const validationConstants = {
  phoneRegExp: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  zipcodeRegExp: /^\d[0-9]{4}$/,
  passwordRegExp:
    // > 8 characters long, should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character OR be 3 words separated by space, dash(-), or underscore(_)
    /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,})|(\w[ -_]\w[ -_]\w)$/,
  nameRegExp: /^[ a-zA-Z0-9\-']+$/,
  NewLineRegex: /\r?\n/g,
  imageRegex: /.*(png|jpg|jpeg)/,
  searchCapitalRegex: /([A-Z])/g,
} as const;

export default validationConstants;
