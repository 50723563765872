import { Lock, LockOpen } from '@mui/icons-material';
import React from 'react';

export default function GroupAccess({
  isPublic,
}: {
  isPublic: boolean | 1 | 0;
}) {
  if (isPublic) {
    return (
      <>
        <LockOpen
          color="primary"
          fontSize="small"
          style={{ paddingRight: '4px' }}
        />{' '}
        Public
      </>
    );
  }
  return (
    <>
      <Lock color="primary" fontSize="small" style={{ paddingRight: '4px' }} />{' '}
      Private
    </>
  );
}
