import { Dispatch } from 'redux';
import { IUser } from 'resources/domain/entity/IUser';
import { Company } from 'resources/domain/entity/Idealer';

enum ActionType {
  SET_USER_STATE = 'AUTH/SET_USER_STATE',
  FAILURE = 'AUTH/FAILURE',
  UPDATE_COMPANY = 'AUTh/UPDATE_USER_COMPANY',
}

export type ILoginState = {
  id?: number;
  name: string;
  email?: string;
  phone_number: string;
  password?: string;
  accomplishments: string | null;
  biography: string | null;
  industry_ref: string | null;
  what_else_to_know: string | null;
  sale_terms_of_service: string | null;
  ebay_user: string | null;
  facebook_user_name: string | null;
  avatar: string | null;
  bannerImage: string | null;
};

type UserReduxState = {
  user: ILoginState & { company?: IUser['company'] | Company };
};

interface loginAction {
  type: ActionType.SET_USER_STATE;
  payload: ILoginState & { company?: IUser['company'] };
}

interface setCompanyOnUserAction {
  type: ActionType.UPDATE_COMPANY;
  payload: Company;
}

interface FailureAction {
  type: ActionType.FAILURE;
}

type Action = loginAction | FailureAction | setCompanyOnUserAction;

const initialState: UserReduxState = {
  user: {
    id: 0,
    name: '',
    email: '',
    phone_number: '',
    accomplishments: null,
    biography: null,
    industry_ref: null,
    what_else_to_know: null,
    sale_terms_of_service: null,
    ebay_user: null,
    avatar: null,
    bannerImage: null,
    facebook_user_name: null,
    company: {
      companyName: '',
      id: 0,
      companyEmail: '',
      companyPhone: '',
      website: '',
      hours: '',
      facebookUrl: '',
      tradingPlatform: '',
      ownerId: null,
      frontendStatus: 1,
      ebayId: '',
    },
  },
};

// user information stored in global state of redux
const reducer = (state = initialState, action: Action): UserReduxState => {
  switch (action.type) {
    case ActionType.SET_USER_STATE:
      return { ...state, user: { ...state.user, ...action.payload } };
    case ActionType.UPDATE_COMPANY:
      return {
        ...state,
        user: { ...state.user, company: action.payload },
      };
    default:
      return state;
  }
};

export default reducer;

export const loginAction =
  (data: ILoginState) =>
  (dispatch: Dispatch<Action>): void => {
    try {
      dispatch({
        type: ActionType.SET_USER_STATE,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

export const setCompanyOnUserAction =
  (data: Company) =>
  (dispatch: Dispatch<Action>): void => {
    try {
      dispatch({
        type: ActionType.UPDATE_COMPANY,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };
