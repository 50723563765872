import { useCallback } from 'react';
import Pusher from 'pusher-js';
import config from 'config/config';
import { trpc } from 'util/trpc';
import { useRoundtableAuth0 } from '../auth/hooks/useRoundtableAuth0';

/**
 * cached client in memory, storing as a promise - so we don't dog-pile during initial render
 */
let clientPromise: Promise<Pusher> | null;

function makeClient(userAccessToken: string) {
  const client = new Pusher(config.pusher.channelsKey, {
    cluster: config.pusher.channelsCluster,
    // see [here](https://pusher.com/docs/channels/server_api/authenticating-users/#using-jsonp-in-pusher-js)
    userAuthentication: {
      transport: 'ajax',
      endpoint: `${config.baseUrl}/pusher/user-auth`,
      headers: {
        authorization: `Bearer ${userAccessToken}`,
      },
    },
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${config.baseUrl}/pusher/auth`,
      headers: {
        authorization: `Bearer ${userAccessToken}`,
      },
    },
  });

  client.connection.bind(
    'error',
    function (err: Error & { data?: { code?: number } }) {
      if (err.data?.code === 4004) {
        console.error('Pusher channels client is over the limit');
      }
    }
  );
  return client;
}

function useSubscribe() {
  const trpcContext = trpc.useContext();
  return useCallback(
    async (client: Pusher) => {
      const channels =
        await trpcContext.client.notifications.channels.get.query();

      channels.forEach((channel) => {
        const subscribed = client.channels
          .all()
          .some((c) => c.name === channel);

        if (subscribed) {
          return;
        }
        client.subscribe(channel);
      });

      return client;
    },
    [trpcContext.client.notifications.channels.get]
  );
}

function usePusherChannelsClient() {
  const { getAccessTokenSilently } = useRoundtableAuth0();
  const subscribeToChannels = useSubscribe();

  if (config.appEnv !== 'production') {
    Pusher.logToConsole = config.pusher.debug;
  }

  return useCallback(async () => {
    if (clientPromise) {
      return await clientPromise;
    }

    clientPromise = getAccessTokenSilently().then(makeClient);

    const client = await clientPromise;
    await subscribeToChannels(client);

    return client;
  }, [getAccessTokenSilently, subscribeToChannels]);
}

export function useSubscribeToPusherChannels() {
  const getClient = usePusherChannelsClient();
  const subscribeToChannels = useSubscribe();
  return useCallback(async () => {
    const client = await getClient();
    await subscribeToChannels(client);
  }, [getClient, subscribeToChannels]);
}

export function useDisconnectPushersChannels() {
  const getClient = usePusherChannelsClient();
  return async () => {
    (await getClient())?.disconnect();
    // remove the promise so we can reconnect later
    clientPromise = null;
  };
}

export function useGetPusherChannelsClient() {
  return usePusherChannelsClient();
}
