import { useState } from 'react';
import { trpc } from 'util/trpc';
import useDebounce from 'hooks/useDebounce';
import type { Notification } from 'components/notification/notifications/getNotificationItemProps';
import { useTotalUnreadNotifications } from 'services/notifications/useTotalUnreadNotifications';
import { useBindToNotificationEvents } from './useBindToEvents';
import useActiveUserId from '../user/useActiveUserId';

type UseNotificationsProps = {
  shouldFetchNotifications: boolean;
  fetchAll: boolean;
};

function useQueryString() {
  const [queryString, setQueryString] = useState<string>('');
  const debouncedQueryString = useDebounce(queryString, 500);

  return {
    queryString: queryString,
    debouncedQueryString,
    onQueryStringChange: setQueryString,
  };
}

export default function useNotifications({
  shouldFetchNotifications,
  fetchAll,
}: UseNotificationsProps) {
  const activeUserId = useActiveUserId();
  const { debouncedQueryString, ...exposedQueryStringControls } =
    useQueryString();

  const notificationsResponse = trpc.notifications.get.useInfiniteQuery(
    {
      limit: 20,
      fetchAll,
      ...(debouncedQueryString.trim() !== ''
        ? { queryString: debouncedQueryString }
        : {}),
    },
    {
      getNextPageParam(lastPage) {
        return lastPage.nextCursor;
      },
      enabled: shouldFetchNotifications,
    }
  );

  useBindToNotificationEvents(
    activeUserId,
    () => {
      if (shouldFetchNotifications) {
        notificationsResponse.refetch();
      }
    },
    ['notification:created']
  );

  const unreadResponse = useTotalUnreadNotifications();

  const markReadMutation = trpc.notifications.markRead.useMutation();

  const readNotifications = notificationsResponse.data?.pages
    .flatMap((p) => p.data)
    .filter((p) => p.markedReadAt !== null)
    .map((x) => x.id);

  async function handleRefetch() {
    await unreadResponse.refetch();
    await notificationsResponse.refetch();
  }

  async function onNotificationChange(notificationId: Notification['id']) {
    await markReadMutation.mutateAsync(
      { ids: [notificationId] },
      {
        async onSuccess() {
          await handleRefetch();
        },
      }
    );
  }

  async function onNotificationsRead() {
    if (notificationsResponse.data) {
      const ids = notificationsResponse.data?.pages.flatMap((p) =>
        p.data.flatMap((n) => (n.markedReadAt === null ? [n.id] : []))
      );

      await markReadMutation.mutateAsync(
        { ids },
        {
          async onSuccess() {
            await handleRefetch();
          },
        }
      );
    }
  }

  return {
    onNotificationChange,
    onNotificationsRead,
    notificationsResponse,
    readNotifications,
    unreadTotal: unreadResponse.data?.total ?? 0,
    ...exposedQueryStringControls,
  };
}
