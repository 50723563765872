interface ConstantsType {
  size: {
    extraLarge: 'extraLarge';
    large: 'large';
    medium: 'medium';
    small: 'small';
  };
  color: {
    default: 'default';
    primary: 'primary';
    secondary: 'secondary';
  };
  placeholder: {
    comment: string;
    search: string;
    name: string;
  };
  variants: {
    h1: 'h1';
    h2: 'h2';
    h3: 'h3';
    filled: 'filled';
    outlined: 'outlined';
    standard: 'standard';
    dialog: 'dialog';
    inline: 'inline';
    static: 'static';
  };
  type: {
    text: 'text';
  };
  adornmentPostion: {
    start: 'start';
    end: 'end';
  };
  children: {
    lorem: string;
  };
  iconPoistion: {
    endAdornment: 'endAdornment';
    startAdornment: 'startAdornment';
  };
  label: {
    name: string;
    demo: string;
    selected: string;
    extraLarge: string;
    large: string;
    medium: string;
    small: string;
    disabled: string;
  };
  logos: {
    GradientLogo: 'GradientLogo';
    WhiteLogo: 'WhiteLogo';
    blueLogoText: 'blueLogoText';
    DarkBlueLogo: 'DarkBlueLogo';
    Favicon: 'Favicon';
    FaviconBlack: 'FaviconBlack';
    BlackLogo: 'BlackLogo';
    WhiteLogoText: 'WhiteLogoText';
    FaviconWhite: 'FaviconWhite';
  };
  value: {
    demo_user: string;
    user_name: string;
    sub_text: string;
    demo_users: string[];
    demo_date: Date;
    item_heading: string;
    item_by: string;
    item_text: string;
    item_company: string;
  };
}

const constants: ConstantsType = {
  size: {
    extraLarge: 'extraLarge',
    large: 'large',
    medium: 'medium',
    small: 'small',
  },
  type: {
    text: 'text',
  },
  color: {
    primary: 'primary',
    secondary: 'secondary',
    default: 'default',
  },
  placeholder: {
    comment: 'Write a comment here ...',
    name: 'Please provide your name',
    search: 'Search ...',
  },
  adornmentPostion: { start: 'start', end: 'end' },
  iconPoistion: {
    endAdornment: 'endAdornment',
    startAdornment: 'startAdornment',
  },
  variants: {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    filled: 'filled',
    outlined: 'outlined',
    standard: 'standard',
    dialog: 'dialog',
    inline: 'inline',
    static: 'static',
  },
  children: {
    lorem:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
  },
  label: {
    name: 'name',
    demo: 'demo options',
    selected: 'selected',
    extraLarge: 'extra large',
    large: 'large',
    medium: 'medium',
    small: 'small',
    disabled: 'disabled',
  },
  logos: {
    GradientLogo: 'GradientLogo',
    WhiteLogo: 'WhiteLogo',
    blueLogoText: 'blueLogoText',
    DarkBlueLogo: 'DarkBlueLogo',
    Favicon: 'Favicon',
    FaviconBlack: 'FaviconBlack',
    BlackLogo: 'BlackLogo',
    WhiteLogoText: 'WhiteLogoText',
    FaviconWhite: 'FaviconWhite',
  },
  value: {
    demo_user: 'Demo User',
    demo_users: ['Demo User 1', 'Demo User 2'],
    user_name: 'User Name',
    sub_text: 'Sub Text',
    item_heading: 'item Heading.',
    item_by: 'Demo User',
    item_text: 'with',
    item_company: 'Demo Company',
    demo_date: new Date(2023, 1, 1),
  },
};

export default constants;
