import React from 'react';
import type { TemplateMatcher } from './useTemplateContent';
import type { Notification } from './getNotificationItemProps';
import { HandleQueryStates } from 'components/utilities/HandleQueryStates';
import UserWithCompany from 'components/utilities/UserWithCompany';
import { Typography } from '@mui/material';
import { useUser } from 'services/user/useUser';
import { usePost } from '../../../services/posts/usePost';

const getUserNameWithCompanyMatcher = (notification: Notification) =>
  ({
    match: /{userWithCompany}|{userName}/g,
    render: () => {
      const userId =
        'userId' in notification.metaData
          ? notification.metaData.userId
          : undefined;
      // eslint-disable-next-line react-hooks/rules-of-hooks -- this is called inside a hook
      const userResponse = useUser(userId);

      if (!userId) {
        return <Typography variant="body2">A member</Typography>;
      }

      return (
        <HandleQueryStates
          response={userResponse}
          loaderAs="skeleton"
          SkeletonProps={{ width: '100%' }}
        >
          {({ response }) => {
            return (
              <UserWithCompany user={response.data} size="small" disableLinks />
            );
          }}
        </HandleQueryStates>
      );
    },
  } satisfies TemplateMatcher);

/**
 * returns a snippet of the post description
 */
const postContentMatcher = (
  notification: Extract<Notification, { type: 'post' }>
) => ({
  match: /{postContent}/g,
  render: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- this is called inside a hook
    const postResponse = usePost(notification.postId);
    return (
      <HandleQueryStates
        response={postResponse}
        loaderAs="skeleton"
        SkeletonProps={{ width: '100%' }}
      >
        {({ response }) => {
          const postSnippet = response.data?.data?.description?.slice(0, 100);
          return <Typography variant="body2">{postSnippet}</Typography>;
        }}
      </HandleQueryStates>
    );
  },
});

const groupMatcher = () => ({
  match: /{group}/g,
  render: () => 'group',
});

const getPostMatcher = () => ({
  match: /{post}/g,
  render: () => 'post',
});

const getCompanyMatcher = () => ({
  match: /{company}/g,
  render: () => 'company',
});

export function getContentMatchers(
  notification: Notification
): TemplateMatcher[] {
  if (notification.type === 'post') {
    switch (notification.metaData.type) {
      case 'comment:added':
      case 'comment:inline_tagged':
      case 'comment:company:inline_tagged':
      case 'comment:reaction_added':
      case 'reaction:added':
      case 'sale:status_changed':
      case 'sale:valid_purchase_attempt_created':
      case 'sale:counter_offer_accepted':
      case '@author:comment:added':
      case '@author:reaction:added':
        return [getUserNameWithCompanyMatcher(notification), getPostMatcher()];
      case '@buyer:sale:purchased':
        return [postContentMatcher(notification)];
      case '@seller:sale:sold':
      case 'post:explicitly_tagged':
      case 'post:inline_tagged':
      case 'post:company:inline_tagged':
        return [
          getUserNameWithCompanyMatcher(notification),
          postContentMatcher(notification),
          getPostMatcher(),
        ];

      case 'comment:removed_by_admin':
      case 'sale:sale_price_increase':
        return [getPostMatcher()];
      default:
        ((_handleAllOptions: never) => null)(notification.metaData);
        return [];
    }
  }
  if (notification.type === 'company') {
    switch (notification.metaData.type) {
      case 'associate:joined':
        return [
          getUserNameWithCompanyMatcher(notification),
          getCompanyMatcher(),
        ];
      default:
        ((_handleAllOptions: never) => null)(notification.metaData.type);
        return [];
    }
  }
  if (notification.type === 'group') {
    switch (notification.metaData.type) {
      case 'post:created':
        return [
          getPostMatcher(),
          getUserNameWithCompanyMatcher(notification),
          groupMatcher(),
        ];
      case 'post:removed_by_admin':
        return [];
      case 'member:requested':
        return [groupMatcher(), getUserNameWithCompanyMatcher(notification)];
      case 'group:approved':
      case 'group:declined':
        return [groupMatcher()];
      default:
        ((_handleAllOptions: never) => null)(notification.metaData);
        return [];
    }
  }
  ((_handleAllOptions: never) => null)(notification);
  return [];
}
