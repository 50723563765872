import OneSignal from 'react-onesignal';
import { PublicUserId } from 'src/services/userService/makePublicUserId';
import { useCallback } from 'react';
import { addDatadogAction } from '../../../util/datadog';

export function useLoginOneSignalUser() {
  return useCallback(async (publicUserId: PublicUserId) => {
    await OneSignal.login(publicUserId);
    addDatadogAction('Push Notifications Set User');
  }, []);
}
