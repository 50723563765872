import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import {
  useQuery,
  type UseQueryOptions,
  type QueryKey,
  type UseQueryResult,
} from '@tanstack/react-query';
import { sendbirdSelectors } from '@sendbird/uikit-react';
import { type GroupChannelClass, MissingSendbirdMethod } from './constants';
import { useSnackbar } from 'components/notification/SnackbarNotification';
import { buildErrorHandlers } from './util/buildErrorHandlers';

const MISSING_METHOD_MAX_RETRIES = 25;

/**
 * these should be queries only
 */
type GroupChannelKeys = 'getChannel' | 'getTotalUnreadChannelCount';

export function useSendBirdGroupChannelQuery<
  Method extends GroupChannelKeys,
  Params extends Parameters<GroupChannelClass[Method]>
>({
  method,
  params,
  keyFn,
  options,
}: {
  method: Method;
  params: Params;
  keyFn?: (method: Method, params: Params) => QueryKey;
  options?: Omit<
    UseQueryOptions,
    'queryKey' | 'retry' | 'queryFn' | 'throwOnError'
  >;
}): UseQueryResult<Awaited<ReturnType<GroupChannelClass[Method]>>> {
  const store = useSendbirdStateContext();
  const { sendMessage } = useSnackbar();

  /**
   * wraps the sdk method in a "retry until resolved" function
   */
  return useQuery<
    Awaited<ReturnType<GroupChannelClass[Method]>>,
    Error,
    Awaited<ReturnType<GroupChannelClass[Method]>>
  >(
    keyFn?.(method, params) ?? [`sb.${method}`],
    // @ts-expect-error -- function overload protect the return type
    async function queryFn() {
      const sdk = sendbirdSelectors.getSdk(store);
      const maybeMethod = sdk?.groupChannel?.[method];
      /**
       * the sdk might not be initialized yet
       */
      if (maybeMethod == null) {
        // no method, throw and react-query will retry
        throw new MissingSendbirdMethod(method);
      }

      /**
       * sendbird uses classes and this references, so we need to bind this method back to its parent before calling
       */
      const boundMethod = maybeMethod.bind(sdk?.groupChannel);
      // @ts-expect-error -- params is an array/tuple by default
      return await boundMethod(...params);
    },
    {
      retryDelay: 250,
      ...options,
      ...buildErrorHandlers({
        maxRetries: MISSING_METHOD_MAX_RETRIES,
        onError(error) {
          sendMessage({ message: error.message, severity: 'error' });
        },
      }),
    }
  );
}
