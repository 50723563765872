import { useRoundtableAuth0, AudienceAttribute } from './useRoundtableAuth0';
import { useCallback } from 'react';

type AudienceKey = keyof AudienceAttribute;

type Getter<T extends AudienceKey> = (
  attribute: T
) => AudienceAttribute[T] | null;

export function useRoundtableAuth0UserAudienceAttribute(): {
  isLoading: boolean;
  isAuthenticated: boolean;
  get<T extends AudienceKey>(attribute: T): AudienceAttribute[T] | null;
};
export function useRoundtableAuth0UserAudienceAttribute<T extends AudienceKey>(
  _attribute: T
): AudienceAttribute[T] | null;
export function useRoundtableAuth0UserAudienceAttribute<T extends AudienceKey>(
  attribute?: T
):
  | AudienceAttribute[T]
  | {
      isLoading: boolean;
      isAuthenticated: boolean;
      get: Getter<T>;
    } {
  const { isAuthenticated, isLoading, user } = useRoundtableAuth0();

  const _get = useCallback(
    (claim: T) => {
      if (!isAuthenticated) {
        return null;
      }

      if (user == null) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - todo: make this more typesafe
      return user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/${claim}`] ?? null;
    },
    [isAuthenticated, user]
  );

  if (attribute) {
    return _get(attribute);
  }

  return {
    isAuthenticated,
    isLoading,
    get: _get,
  };
}
