import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';
import React from 'react';

export function LoadingSkeletons({
  count = 1,
  boxProps,
  skeletonProps,
}: {
  count: number;
  boxProps?: BoxProps;
  skeletonProps?: SkeletonProps;
}) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <Box height="7vh" key={i} px={2} py={0} my={0} {...boxProps}>
          <Skeleton
            animation="wave"
            height="100%"
            width="100%"
            sx={{
              p: 0,
              m: 0,
            }}
            {...skeletonProps}
          />
        </Box>
      ))}
    </>
  );
}
