import React, { useState, useCallback, useEffect } from 'react';
import * as serviceWorker from 'serviceWorker';
import Button from '@mui/material/Button';
import { useSnackbar } from '../components/notification/SnackbarNotification';
import Loader from '@mui/material/CircularProgress';

function RefreshButton({ onClick }: { onClick(): void }) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = useCallback(() => {
    setLoading(true);
    onClick();
  }, [onClick, setLoading]);

  return (
    <Button
      size="medium"
      variant="text"
      color="inherit"
      onClick={handleClick}
      endIcon={loading ? <Loader size={10} /> : undefined}
      disabled={loading}
    >
      Refresh
    </Button>
  );
}

function useServiceWorker() {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  /**
   * we have a new worker/app update - store it in state
   */
  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
  }, []);

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    waitingWorker?.addEventListener('activate', () => {
      // the new service worker is active, reload to use the new resources
      setWaitingWorker(null);
      window.location.reload();
    });
  }, [waitingWorker]);

  // register the service worker
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorker.register({
      onUpdate: onSWUpdate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to register once
  }, []);

  return { waitingWorker, reloadPage };
}

export function useServiceWorkerWithUpdatePrompt() {
  const { waitingWorker, reloadPage } = useServiceWorker();
  const { sendMessage } = useSnackbar();
  useEffect(() => {
    if (waitingWorker) {
      sendMessage({
        message: 'A new version of the app is available!',
        severity: 'info',
        action: <RefreshButton onClick={reloadPage} />,
        vertical: 'bottom',
        horizontal: 'right',
        timeout: 5000,
      });
    }
  }, [waitingWorker, sendMessage, reloadPage]);
}
