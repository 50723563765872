/* eslint-disable @typescript-eslint/no-explicit-any -- this file is deprecated  */
import { useEffect } from 'react';
import { useSnackbar } from 'components/notification/SnackbarNotification';
import axios, { AxiosResponse } from 'axios';
import config from '../config/config';
import { withData, withError } from './api';
import { useLogout } from 'hooks/useLogout';
import { useAuth0 } from '@auth0/auth0-react';
import { useHandleError } from 'hooks/useHandleError';

export const api = axios.create({
  baseURL: config.baseUrl,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * @deprecated - use trpc instead
 */
export const useAxiosInterceptor = (): void => {
  const { sendMessage } = useSnackbar();
  const { logout } = useLogout();
  const { getAccessTokenSilently } = useAuth0();
  const handleError = useHandleError();

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use(async (req) => {
      const accessToken = await getAccessTokenSilently({
        scope: 'offline_access',
      });
      if (accessToken == null && process.env.NODE_ENV === 'development') {
        console.warn('access token is empty, request might fail');
        return req;
      }
      req.headers.authorization = `Bearer ${accessToken}`;
      return req;
    });

    const responseInterceptor = api.interceptors.response.use(
      (res) => {
        return withData(res.data) as AxiosResponse<any>;
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          sendMessage({
            message:
              'Your session has expired. Please re-authenticate to continue.',
            severity: 'error',
          });
          // Essentialy waiting for 1.5 seconds to let user understand the session expiry error and
          // then redirecting to login page
          // setTimeout(() => logout(), 1500)
        }

        if (
          err.response &&
          err.response.status === 400 &&
          'details' in err.response.data
        ) {
          // show  user validation errors
          handleError(err.response.data);
        }

        return withError(err?.response?.data?.error);
      }
    );

    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [getAccessTokenSilently, handleError, logout, sendMessage]);
};

/**
 * @deprecated - use trpc instead
 */
export function get<P>(url: string, params?: P): Promise<any> {
  return api({
    method: 'get',
    url,
    params,
  });
}
/**
 * @deprecated - use trpc instead
 */
export function post<D, P>(url: string, data: D, params?: P): any {
  return api({
    method: 'post',
    url,
    data,
    params,
  });
}
/**
 * @deprecated - use trpc instead
 */
export function postFile<D, P>(url: string, data: D, params?: P): any {
  return api({
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    url,
    data,
    params,
  });
}
/**
 * @deprecated - use trpc instead
 */
export function put<D, P>(url: string, data: D, params?: P): any {
  return api({
    method: 'put',
    url,
    data,
    params,
  });
}
/**
 * @deprecated - use trpc instead
 */
export function patch<D, P>(url: string, data: D, params?: P): any {
  return api({
    method: 'patch',
    url,
    data,
    params,
  });
}
/**
 * @deprecated - use trpc instead
 */
export function remove<P>(url: string, params?: P): any {
  return api({
    method: 'delete',
    url,
    params,
  });
}
