import type SendbirdChat from '@sendbird/chat';
import type { SendbirdGroupChat } from '@sendbird/chat/groupChannel';

export type GroupChannelClass = SendbirdGroupChat['groupChannel'];

export class MissingSendbirdMethod extends Error {
  constructor(method: keyof GroupChannelClass | keyof SendbirdChat) {
    super(`${method} is not defined on sendbird sdk`);
  }
}
