import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import colors from '../theme/colors';
import headerBg from 'assets/images/background-mixedcolors.jpg';

/**
 * @deprecated - almost all styles should be either set via {@Link theme} or as part of the component itself
 * @description: This should be actively taken apart and never added to.
 *  styling should be in 1 of several acceptable places
 *  1. declared as a theme default customizing a MUI component via createTheme()
 *  2. as close to the component as possible, either via makeStyles or styled(Component)
 *  3. via <Box>, example: <Box display="flex" alignItems="center" justifyContent="center" ...etc />
 */
export const useThemeStyles = makeStyles((theme: Theme) =>
  createStyles({
    p0: {
      padding: 0,
    },

    bold: {
      fontWeight: 'bold',
    },

    multicolorBackground: {
      backgroundImage: `linear-gradient(rgba(255,255,255,.05), rgba(255,255,255,.05)), url(${headerBg})`,
      width: '100%',
      height: 'auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },

    my20: {
      margin: '20px 0',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    tagModalCommon: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #707070',
      marginBottom: '20px',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      width: '235px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-evenly',
    },

    cursorA: {
      cursor: 'pointer',
    },

    positionRelative: {
      position: 'relative',
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      border: '1px solid #ccc',
      borderRadius: '10px',
      display: 'flex',
      flexFlow: 'column',
      width: '40%',
      outline: 'none',
      '&:focus': {
        border: '1px solid #ccc',
      },
    },

    textRight: {
      textAlign: 'right',
    },

    buttonOutline: {
      padding: '10px 26px',
      borderRadius: '100px',
      textTransform: 'capitalize',
      backgroundColor: '#fff',
      border: '1px solid #074D93',
      color: '#074D93',
      '&:hover': {
        backgroundColor: '#074D93',
        color: '#fff',
      },
    },
    button: {
      padding: '10px 26px',
      borderRadius: '100px',
      textTransform: 'capitalize',
      backgroundColor: '#074D93',
      border: '1px solid #074D93',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#074D93',
      },
    },
    widthInput: {
      width: '100%',
    },

    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      height: '28px',
    },

    listSpacing: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },

    commonFlex: {
      display: 'flex',
      alignItems: 'center',
    },

    displayFlex: {
      display: 'flex',
    },
    justifyContentBetween: {
      justifyContent: 'space-between',
    },

    w100: {
      width: '100%',
    },
    commonFlexHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '2px',
      paddingBottom: '2px',
    },

    marginAddRight: {
      marginRight: '15px',
    },

    mr20: {
      marginRight: '20px',
    },

    py30: {
      padding: '30px 0',
    },
    py20: {
      padding: '20px 0',
    },

    dropdown: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: '0',
      cursor: 'pointer',
    },

    listColorActive: {
      backgroundColor: '#074D9314',
      borderRadius: '0px',
      borderBottom: '4px solid #fff',
    },
    toastSuccess: {
      '& div': {
        backgroundColor: '#2e7d32',
      },
    },
    toastError: {
      '& div': {
        backgroundColor: '#FF0000',
      },
    },

    memberPrivateDigit: {
      color: '#707070',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& svg': {
        color: '#074D93',
        width: '16px',
        marginRight: '5px',
      },
    },

    font16: {
      fontSize: '16px',
    },

    mb10: {
      marginBottom: '10px',
    },

    mb20: {
      marginBottom: '20px !important',
    },
    lineClamp: {
      WebkitLineClamp: 1,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    py15: {
      padding: '15px 0',
    },

    infiniteScroll: {
      overflow: 'hidden !important',
    },
    endMessage: {
      textAlign: 'center',
      padding: '15px',
    },
    createPostModal: {
      boxShadow: '0px 3px 25px #00000026',
      borderRadius: '10px',
      width: '60%',
      background: '#fff',
      maxHeight: 'calc(100% - 300px)',
      overflow: 'auto',
      '&:focus-visible': {
        border: 'none',
      },
    },

    createPostHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #707070',
      padding: '10px 20px',
    },

    uniqueSelect: {
      width: '200px',
      '& div': {
        '& div': {
          '&::before': {
            borderBottom: 'none !important',
          },
          '&::after': {
            borderBottom: 'none !important',
          },
        },
      },
    },
    textDecorationAnchor: {
      color: '#005292',
      textDecoration: 'underline',
    },

    commentAlignBox: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '4px 10px',
    },

    mt20: {
      marginTop: '20px',
    },

    createPostAvatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px 15px',
      '& ul': {
        '& li': {
          padding: 0,
        },
      },
    },

    grayColor: {
      color: 'gray',
    },

    mr5: {
      marginRight: '5px',
    },

    ml10: {
      marginLeft: '10px',
    },

    ml20: {
      marginLeft: '20px',
    },

    m5: {
      margin: '5px',
    },

    p10: {
      padding: '10px',
    },

    p20: {
      padding: '20px !important',
    },

    m20: {
      margin: '20px',
    },
    redColor: {
      color: '#ff0000',
    },

    mainProfile: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '10px',
      boxShadow: '0px 3px 6px #0000000D',
      minHeight: '570px',
      padding: '0 20px',
    },

    mainDiscoverProfile: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '10px',
      boxShadow: '0px 3px 6px #0000000D',
      padding: '0 20px',
    },

    profileHeader: {
      padding: '15px',
      borderBottom: '1px solid #707070',
      marginBottom: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paddingContainer: {
      padding: '0 15px',
    },
    buttonBgDot: {
      backgroundColor: '#00000014',
    },
    marginAddGrid: {
      marginBottom: '10px',
    },
    marginTopAdd: {
      marginTop: '10px',
    },

    editColor: {
      color: '#074D93',
    },
    imageChange: {
      width: '350px',
    },

    feedInfoCard: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0px 3px 6px #0000000D',
      padding: '15px',
      marginBottom: '15px',
    },

    feedInfoBottom: {},

    inlineBtns: {
      '& button': {
        marginRight: '10px',
        marginBottom: '5px',
      },
    },

    feedcard: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0px 3px 6px #0000000D',
      position: 'relative',
    },

    feedcardMid: {},
    customCommentBox: {
      backgroundColor: colors.grey.archroma,
      borderRadius: '50px',
      '& input': {
        fontSize: '14px',
        padding: '12px 15px',
      },
      '& textarea': {
        padding: '10px 60px 10px 20px',
      },
    },
    borderBlackSvg: {
      borderRight: '1px solid #000',
      height: '100%',
      paddingRight: '6px',
    },
    wordBreak: {
      wordBreak: 'break-word',
    },
    companyHeader: {
      padding: '15px',
      borderBottom: '1px solid #707070',
      marginBottom: '25px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    feedInfoCardImg: {
      '& img': {
        width: '100%',
        borderRadius: '10px',
        maxHeight: '250px',
        objectFit: 'cover',
        objectPositin: 'center',
      },
    },
    modalHeight: {
      minHeight: '300px',
      height: '300px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      marginBottom: '20px',
      '& li': {
        '& div': {
          paddingLeft: 0,
        },
        '& button': {
          marginRight: '10px',
        },
      },
    },
    highlightsCard: {
      overflow: 'hidden',
      boxShadow: '0px 3px 6px #0000000d',
      borderRadius: '10px',
      marginBottom: '15px',
      backgroundColor: '#fff',
    },

    membersSection: {
      padding: '15px',
    },

    membersSectionHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
      '& h5': {
        color: '#707070',
        margin: '0px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginRight: '5px',
        },
      },
      '& a': {
        color: '#074D93',
      },
    },

    highlightsList: {
      '& img': {
        width: '24px',
        minWidth: '24px',
        borderRadius: '100%',
        height: '24px',
        marginRight: '-4px',
      },
    },
    listImgHighlight: {
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
    buttonBehaviorChange: {
      '& button': {
        '&:hover': {
          backgroundColor: colors.primary.dark,
          borderRadius: '50px',
          color: theme.palette.common.white,
          border: '1px solid transparent',
          fontWeight: 500,
          fontSize: '12px',
          boxShadow: 'none',
          textTransform: 'capitalize',
          '& span': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  })
);
