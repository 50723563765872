import { trpc } from 'util/trpc';
import { useBindToRequestsEvent } from '../notifications/useBindToChannelEvent';
import { UserId } from 'src/services/userService/models/user.model';

const pageSize = 30;

export function useGroupsForUser({
  groupNameQuery,
  status,
  limit,
  userId,
}: {
  groupNameQuery?: string;
  status?: 'active' | 'pending';
  limit?: number;
  userId?: UserId;
} = {}) {
  const myGroupsResponse = trpc.groups.getByUser.useInfiniteQuery(
    {
      name: groupNameQuery,
      limit: limit ?? pageSize,
      ...(status === 'active' && { status: 1 }),
      ...(userId != null && { userId }),
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam(lastPage) {
        return lastPage.nextCursor;
      },
    }
  );

  useBindToRequestsEvent('groupRequest:updated', () => {
    myGroupsResponse.refetch();
  });

  return myGroupsResponse;
}
