import { createTRPCReact } from '@trpc/react-query';
import { httpBatchLink, loggerLink } from '@trpc/client';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import config from 'config/config';
import type { AppRouter } from 'src/trpc/appRouter';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

const TRPC_URL = `${config.baseUrl}/trpc`;
export const trpc = createTRPCReact<AppRouter>();

export type RouterOutput = inferRouterOutputs<AppRouter>;
export type RouterInput = inferRouterInputs<AppRouter>;

export function useTrpcClient() {
  const { getAccessTokenSilently } = useAuth0();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink({
          enabled: (opts) =>
            (config.appEnv === 'development' &&
              typeof window !== 'undefined') ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: TRPC_URL,
          // removes maxURULength to support large image uploads (which result in large url sizes because of the urls included in a post)
          //  if this becomes an issue we will need send the image urls in chunks as an update.
          // maxURLLength: 3000, // a suitable size
          async headers() {
            const accessToken = await getAccessTokenSilently({
              scope: 'offline_access',
            });

            if (accessToken == null && config.env === 'development') {
              console.warn('access token is empty, request might fail');
              return {};
            }

            return {
              authorization: `Bearer ${accessToken}`,
            };
          },
        }),
      ],
    })
  );
  return trpcClient;
}
