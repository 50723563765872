import { useEffect, useRef } from 'react';

export function useComponentWillUnmount() {
  const willComponentUnmount = useRef(false);

  useEffect(() => {
    return () => {
      willComponentUnmount.current = true;
    };
  }, []);

  return willComponentUnmount;
}
