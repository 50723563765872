import React from 'react';
import FeedBox from '../../utilities/FeedBox';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import colors from '../../../theme/colors';
import MemoAvatar from '../../common/images/MemoAvatar';

export function RoundedListItem({
  primaryText,
  secondaryText,
  icon,
  iconColor,
  status = 'default',
  ...props
}: {
  primaryText: React.ReactNode;
  secondaryText?: React.ReactNode;
  icon: string | React.ReactElement;
  iconColor?: string;
  status?: 'active' | 'default';
} & (
  | {
      actions?: (React.ComponentProps<typeof MemoAvatar> & {
        icon: React.ReactNode;
        onClick(): void | undefined | Promise<void | undefined>;
      })[];
    }
  | {
      onClick?(): void;
    }
)) {
  return (
    <FeedBox borderRadius="50px" bgcolor={colors.white}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        bgcolor={status === 'active' ? colors.blue.active : colors.blue.faded}
        borderRadius="50px"
        sx={{ '&:hover': { bgcolor: colors.blue.active, cursor: 'pointer' } }}
        onClick={'onClick' in props ? props.onClick : undefined}
      >
        <ListItemAvatar>
          <MemoAvatar color={iconColor}>{icon}</MemoAvatar>
        </ListItemAvatar>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          width="100%"
        >
          {primaryText}
          <Typography variant="caption" align="left">
            {secondaryText}
          </Typography>
        </Box>
        {'actions' in props &&
          props.actions?.map(({ icon, onClick, ...a }, index) => (
            <IconButton key={index} onClick={onClick} size="large">
              <MemoAvatar {...a}>{icon}</MemoAvatar>
            </IconButton>
          ))}
      </Box>
    </FeedBox>
  );
}
