import { useCallback } from 'react';
import OneSignal from 'react-onesignal';

export function usePromptForPushNotifications() {
  return useCallback(
    async (
      {
        promptStyle,
        force,
      }: {
        promptStyle?: 'slidedown' | 'native';
        force?: boolean;
      } = { promptStyle: 'slidedown' }
    ) => {
      if (promptStyle === 'native') {
        await OneSignal.Notifications.requestPermission();
        return;
      }

      await OneSignal.Slidedown.promptPush({ force });
    },
    []
  );
}
