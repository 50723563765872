import React, { isValidElement, useEffect } from 'react';
import type { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useDatadogAction } from 'util/datadog';

type RumRouteComponentType =
  | RouteProps['component']
  | RouteProps['children']
  | RouteProps['render'];

function isClassComponent(
  component: RumRouteComponentType
): component is React.ComponentClass {
  return (
    typeof component === 'function' && !!component.prototype?.isReactComponent
  );
}

function isFunctionComponent(
  component: RumRouteComponentType
  // eslint-disable-next-line
): component is React.FunctionComponent {
  return (
    typeof component === 'function' &&
    // eslint-disable-next-line no-prototype-builtins
    component.hasOwnProperty('props') &&
    isValidElement(component)
  );
}

function isReactRouterComponent(
  component: RumRouteComponentType
): component is RouteProps['component'] {
  return isClassComponent(component) || isFunctionComponent(component);
}

export const withRum = (component: RumRouteComponentType) =>
  function RumView(props: RouteComponentProps): React.ReactElement {
    const startView = useDatadogAction('startView');

    useEffect(() => {
      startView({ name: props.match.path });
    }, [startView, props.match.path]);

    if (!component) {
      return <>{component}</>;
    } else if (isReactRouterComponent(component)) {
      const Component = component;
      return <Component {...props} />;
    } else if (component instanceof Function) {
      return <>{component(props)}</>;
    }

    return <>{component}</>;
  };
