import React from 'react';
import {
  List,
  ListItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useThemeStyles } from '../../styles';
import useLang from 'hooks/useLang';
import { Link, useLocation } from 'react-router-dom';
import { useUserRoleIncludes } from 'hooks/useUserRoles';
import { Grid } from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import config from 'config/config';
import { cx } from 'util/classnames';

type ItemBase = {
  title: string;
  icon: React.ReactElement;
  isAdminFunctionality?: boolean;
};

export type HeaderMenuItemLink = ItemBase & {
  link: string;
};

export type HeaderMenuItemClick = ItemBase & {
  handleItemClick(): void;
};

export type HeaderMenuItem = HeaderMenuItemLink | HeaderMenuItemClick;

const useStyles = makeStyles((theme) => ({
  tabList: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0 20px',
    },
    '& a': {
      color: '#fff',
    },
  },
}));

function longerMatchExists(initial: string, other: string[], target: string) {
  return other.some((s) => target.startsWith(s) && s.length > initial.length);
}

export default function HeaderIcons({ tabs }: { tabs: HeaderMenuItemLink[] }) {
  const commonStyleClasses = useThemeStyles();
  const isAdmin = useUserRoleIncludes('Admin');
  const styleClasses = useStyles();
  const location = useLocation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const otherLinks = tabs.map((element) => element.link);

  return (
    <List
      className={`${commonStyleClasses.commonFlex} ${styleClasses.tabList} ${commonStyleClasses.p0}`}
      sx={
        isMobile
          ? {
              justifyContent: 'space-evenly',
              width: '100%',
            }
          : {}
      }
    >
      {tabs
        .filter(
          (element) =>
            (element.isAdminFunctionality && isAdmin) ||
            !element.isAdminFunctionality
        )
        .map((element) => (
          <Link
            key={element.title}
            to={element.link}
            style={{ textDecoration: 'none' }}
          >
            <HeaderItem
              icon={element.icon}
              title={element.title}
              active={
                location.pathname.startsWith(element.link) &&
                // If there is a more exact link in the menu bar, select that one instead
                !longerMatchExists(element.link, otherLinks, location.pathname)
              }
            />
          </Link>
        ))}
      {config.coinbidz.show && (
        <a
          href={config.coinbidz.url}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noreferrer"
        >
          <HeaderItem title="CoinBidz" icon={<ReceiptLongIcon />} />
        </a>
      )}
    </List>
  );
}

interface HeaderItemProps {
  icon: React.ReactNode;
  title: string;
  active?: boolean;
}

function HeaderItem({ icon, title, active }: HeaderItemProps) {
  const commonStyleClasses = useThemeStyles();
  const { f } = useLang();

  return (
    <ListItem className={cx(active && commonStyleClasses.listColorActive)}>
      <Grid container spacing={1}>
        <Grid item>{icon}</Grid>
        <Grid item display={{ xs: 'none', md: 'block' }}>
          <Typography>{f(title)}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}
