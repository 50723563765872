import OneSignal from 'react-onesignal';
import { useCallback } from 'react';
import { addDatadogAction } from '../../../util/datadog';

export function useLogoutOneSignalUser() {
  return useCallback(async () => {
    await OneSignal.logout();
    addDatadogAction('Push Notifications Clear User');
  }, []);
}
