import { trpc } from '../../util/trpc';
import { useBindToNotificationEvents } from './useBindToEvents';
import useActiveUserId from 'services/user/useActiveUserId';

export function useTotalUnreadNotifications() {
  const activeUserId = useActiveUserId();
  const unreadResponse = trpc.notifications.getTotalUnread.useQuery();

  useBindToNotificationEvents(
    activeUserId,
    () => {
      unreadResponse.refetch();
    },
    ['notification:created']
  );
  return unreadResponse;
}
