import { useState, useEffect } from 'react';
import { useIsMounted } from './useIsMounted';

/**
 * prevents constant value changes from updating state and causing re-render
 */
export default function useDebounce<T>(value: T, delay: number | null): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const isMounted = useIsMounted();

  useEffect(
    () => {
      if (delay === 0 || delay == null) {
        // don't debounce
        if (value !== debouncedValue) {
          // todo: the check above does not handle complex values (objects) very well
          setDebouncedValue(value);
        }
        return;
      }
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        if (isMounted()) {
          // only set the value if the component is still mounted
          setDebouncedValue(value);
        }
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay, debouncedValue, isMounted]
  );

  return debouncedValue;
}
