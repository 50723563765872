import React from 'react';
import { useActiveUser } from 'services/user/useActiveUser';
import { Link } from 'react-router-dom';
import config from 'config/config';

type Props = {
  className?: string;
  subject: string;
  error?: {
    message: string;
    code: string;
  };
  children?: React.ReactChild;
};

function ContactEmailLink({ className, subject, error, children }: Props) {
  const { data: user } = useActiveUser();

  const userId = user?.id;
  const deviceInfo = navigator.userAgent;

  const appInfoSetion = `
      ----------------------------------------------
        
      App Data:
        User ID: ${userId ?? 'N/A'} 
        Last Known Page: ${window.location.href}
        Device info: ${deviceInfo}
  `;

  const body = error
    ? `
        Hello,
        
        I have experienced a problem.
        
        ${appInfoSetion}
        Error code: ${error.code}
        Error message: ${error.message}
      `
    : `
        Hello,

        <Add your question or concern here>

        ${appInfoSetion}
      `;

  const handleContactClick = () => {
    const mailtoLink = `mailto:${
      config.customerSupportEmail
    }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <Link className={className} to="#" onClick={handleContactClick}>
      {children}
    </Link>
  );
}

export default ContactEmailLink;
