import React from 'react';
import { ThemeProvider, Theme, StyledEngineProvider, Box } from '@mui/material';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { palette } from './colors';
import { ClassNameMap } from '@mui/styles/withStyles';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    offWhite: Palette['primary'];
  }

  interface PaletteOptions {
    offWhite: PaletteOptions['primary'];
  }
}

export interface ColorsProps {
  /**
   * All the color options for the palette:
   */
  color: 'offWhite' | 'primary' | 'secondary';
  /**
   * All the type options for the color:
   */
  type: 'main' | 'light' | 'dark';
}

// Creating a custom theme for the color palette:
export const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: "'proxima-nova', sans-serif",
    },
    palette,
    overrides: {
      MuiAccordion: {
        root: {
          borderRadius: 8,
          ':first-of-type': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          ':last-of-type': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
          '::before': {
            display: 'none',
          },
        },
      },
      MuiCard: {
        root: {
          borderRadius: 15,
        },
      },
      MuiCardHeader: {
        root: {
          paddingBottom: 0,
        },
      },
      MuiFilledInput: {
        root: {
          borderRadius: '50px',
          borderTopLeftRadius: undefined,
          borderTopRightRadius: undefined,
          // push the input to the right, since we have a heavy border radius
          paddingLeft: '24px',
        },
        multiline: {
          // push the input to the right, since we have a heavy border radius
          padding: '0px 0px 0px 24px',
        },
      },
    },
  })
);

// Style logic for the component:
export const useStyles = makeStyles({
  boxColor: {
    width: '200px',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    backgroundColor: (props: ColorsProps) =>
      theme.palette[props.color][props.type],
  },
});

/**
 * Demo Colors component to display all colors available in the palette:
 */
export function CommonColors({
  color = 'offWhite',
  type = 'main',
}: ColorsProps) {
  const styleClasses: ClassNameMap = useStyles({
    color,
    type,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box className={`${styleClasses.boxColor}`} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
