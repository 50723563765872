import { MissingSendbirdMethod } from '../constants';

export function buildErrorHandlers({
  maxRetries,
  onError,
}: {
  maxRetries: number;
  onError(error: Error): void;
}) {
  return {
    throwOnError(error: Error) {
      return !(error instanceof MissingSendbirdMethod);
    },
    onError(error: Error) {
      if (error instanceof MissingSendbirdMethod) {
        return;
      }
      onError(error);
    },
    retry(failureCount: number, error: Error) {
      if (failureCount < 3) {
        // retry everything for the first 3 failures
        return true;
      }

      return (
        error instanceof MissingSendbirdMethod && failureCount < maxRetries
      );
    },
  };
}
