import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ sx }: { sx?: SxProps }) {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={5}
      sx={sx}
    >
      <CircularProgress />
    </Box>
  );
}
