import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { useEffect } from 'react';
import { useBindToChannelLazily } from './useBindToChannelLazily';
import type { GroupId } from 'src/services/groupService/models/group.model';
import type { UserId } from 'src/services/userService/models/user.model';
import type {
  Channel,
  ChannelEvent,
  ChannelEventData,
  GroupChannel,
  NotificationChannel,
} from 'src/services/notificationService/channelsService/types';

function useBindToChannelEvents<
  C extends Channel,
  E extends ChannelEvent<C>,
  Data extends ChannelEventData<C, E> = ChannelEventData<C, E>
>(
  channel: Channel | null,
  listener: (args: { event: E; data: Data }) => void,
  events: E[],
  options?: { enforceSingleListener?: boolean }
) {
  const bindEventListener = useBindToChannelLazily();
  const componentWillUnmount = useComponentWillUnmount();

  useEffect(() => {
    if (channel == null) {
      // we allow channel to be nullish to make it easier for the calling component - ideally we would never need to do this
      return;
    }

    const boundEvents = events.map((eventName) => {
      return bindEventListener(channel, {
        // in general, we want to enforce single listener for post related events, since we use it at the fetching level
        enforceSingleListener: options?.enforceSingleListener ?? false,
        eventName,
        listener(data) {
          listener({ event: eventName, data: data as Data });
        },
      });
    });

    // unbind on unmount
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- we intentionally want to reference this ref assuming it has "changed" been set to true
      if (componentWillUnmount.current) {
        boundEvents.forEach((b) => {
          b.unbind();
        });
      }
    };
  }, [
    bindEventListener,
    componentWillUnmount,
    events,
    channel,
    listener,
    options?.enforceSingleListener,
  ]);
}

export function useBindToNotificationEvents<
  C extends NotificationChannel,
  E extends ChannelEvent<C>,
  Data extends ChannelEventData<C, E> = ChannelEventData<C, E>
>(
  /**
   * todo: ideally the caller always provides an id
   */
  userId: UserId | null,
  listener: (args: { event: E; data: Data }) => void,
  events: E[],
  options?: { enforceSingleListener?: boolean }
) {
  return useBindToChannelEvents<C, E, Data>(
    userId != null ? `private-notifications-${userId}` : null,
    listener,
    events,
    options
  );
}

export function useBindToGroupEvents<
  C extends GroupChannel,
  E extends ChannelEvent<C>,
  Data extends ChannelEventData<C, E> = ChannelEventData<C, E>
>(
  /**
   * todo: force the caller to always provide an id
   */
  groupId: GroupId | null | undefined,
  listener: (args: { event: E; data: Data }) => void,
  events: E[],
  options?: { enforceSingleListener?: boolean }
) {
  return useBindToChannelEvents<C, E, Data>(
    groupId != null ? `private-group-${groupId}` : null,
    listener,
    events,
    options
  );
}
