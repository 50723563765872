import { useRef } from 'react';
import Pusher, { type Channel as PusherChannel } from 'pusher-js';
import { useGetPusherChannelsClient } from './pusherChannels';
import type {
  Channel,
  ChannelEvent,
  ChannelEventData,
} from 'src/services/notificationService/channelsService/types';

type Event = ChannelEvent<Channel>;

/**
 * returns a function to bind a listener to a channel event
 */
export function useBindToChannelLazily() {
  const getClient = useGetPusherChannelsClient();
  /**
   * TL;DR; allows one listener per channel event - locally (related to the hook that uses this hook)
   * The purpose of this ref is to prevent re-renders from causing a re-bind for the same event that was initialized already in a hook.
   * We're supporting the tracking of multiple bound events here because sometimes this ref is leveraged in a loop
   * see {@link useBindToChannelEvents}.
   */
  const boundEvents = useRef<Set<Event>>(new Set());

  function setBound(eventName: Event) {
    boundEvents.current?.add(eventName);
  }

  function setUnbound(eventName: Event) {
    boundEvents.current?.delete(eventName);
  }

  function eventIsBound(eventName: Event) {
    return boundEvents.current?.has(eventName);
  }

  /**
   * this ref is used to ensure the listener is always the latest version of the listener,
   * so it can reference changed values around it closure
   * @exmaple
   *
   * bind(channel, event, () => {
   *  if (somePropInClosureScopeThatChanges) { // <= this prop will be the latest value so the logic will work
   *    // do something
   *  }
   * })
   */
  const eventListenerRef =
    useRef<
      (data: ChannelEventData<Channel, ChannelEvent<Channel>> | null) => void
    >();

  return function bindToChannel<
    C extends Channel,
    E extends ChannelEvent<C>,
    D extends ChannelEventData<C, E>
  >(
    channelName: Channel,
    {
      eventName,
      listener,
      enforceSingleListener,
    }: {
      eventName: E;
      listener: (data: D) => void;
      /**
       * if true, will unbind any existing listeners for this event, globally
       */
      enforceSingleListener?: boolean;
    }
  ) {
    let channel: PusherChannel | undefined;

    const actions = {
      /**
       * see [docs](https://github.com/pusher/pusher-js#bind-and-unbind)
       */
      unbind: () => {
        if (enforceSingleListener) {
          // unbind every listener for this event
          channel?.unbind(eventName);
          setUnbound(eventName);
          return;
        }
        // unbind just the provided listener
        channel?.unbind(eventName, listener);
        setUnbound(eventName);
      },
    };

    // update the ref on every render, so its closure is aware of the latest values
    // @ts-expect-error - the listener type is narrowed above and will be safe at the call sight
    eventListenerRef.current = listener;

    if (eventIsBound(eventName)) {
      // already bound to event
      return actions;
    }

    function bindToChannelsClient(client: Pusher) {
      // channel could be undefined? maybe because it's not subscribed to yet on a mount
      channel = client.channel(channelName);

      if (enforceSingleListener) {
        // only allow 1 listener per event per channel - globally
        channel?.unbind(eventName);
      }

      channel?.bind(eventName, (...args: [D]) =>
        eventListenerRef?.current?.(...args)
      );
      setBound(eventName);
    }

    getClient().then((client) => {
      bindToChannelsClient(client);
    });

    return actions;
  };
}
