import { useAuth0 } from '@auth0/auth0-react';
import { Company } from 'resources/domain/entity/Idealer';
import { ILoginState } from 'state_management/reducers/authReducer';
import { useCallback } from 'react';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import config from '../../../config/config';
import { omit } from 'lodash';
import { UserId } from 'src/services/userService/models/user.model';

// provided by the app
export type AudienceAttribute = {
  roles: ('Admin' | 'Cypress')[];
  loginCount: number;
  /**
   * for first time logins, this might be nullish
   */
  userId?: UserId;
};

// application fields  are "private", should be accessed safely with .get
type Auth0ApplicationAttributes = {
  'https://api.roundtabletrading.com/roles': AudienceAttribute['roles'];
  'https://api.roundtabletrading.com/loginCount': AudienceAttribute['loginCount'];
  'https://api.roundtabletrading.com/userId': AudienceAttribute['userId'];
};

// todo: decode this...
export type User = ILoginState &
  Auth0ApplicationAttributes & {
    nickname: string;
    name: string;
    updated_at: string;
    email: string;
    email_verified: boolean;
    sub: string;
    id: number;
    company?: Company;
  };

export function useRoundtableAuth0():
  | (Omit<ReturnType<typeof useAuth0>, 'user'> & {
      user: User;
      isLoading: false;
      isAuthenticated: true;
    })
  | (Omit<ReturnType<typeof useAuth0>, 'user'> & {
      user?: never;
      isLoading: true;
      isAuthenticated: boolean;
    })
  | (Omit<ReturnType<typeof useAuth0>, 'user'> & {
      user?: never;
      isLoading: boolean;
      isAuthenticated: false;
    }) {
  const auth0State = useAuth0();
  const { getAccessTokenSilently: auth0GetAccessTokenSilently } = auth0State;

  /**
   * some errors thrown here might require action, so we're centralizing the error handling here
   */
  const getAccessTokenSilently = useCallback(
    async (options?: GetTokenSilentlyOptions): Promise<string> => {
      const token = await auth0GetAccessTokenSilently(options).catch(
        (error) => {
          if (
            error.error === 'login_required' ||
            error.error === 'consent_required'
          ) {
            // this might happen when we ignoreCache: true on a first time login
            // (an attempt to get a new access token with an id attached)
            // todo: look into this.
            // reference: https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/3
            if (config.appEnv === 'development') {
              const stack = new Error().stack;
              console.warn(
                'getAccessTokenSilently failed with login_required or consent_required',
                stack
              );
              return null;
            }
          } else {
            throw error;
          }
        }
      );
      if (token == null) {
        // we failed to get a new token, try one more time - but don't skip the cache
        return await getAccessTokenSilently(omit(options, 'ignoreCache'));
      }
      return token;
    },
    [auth0GetAccessTokenSilently]
  );

  return {
    ...auth0State,
    getAccessTokenSilently,
  };
}
