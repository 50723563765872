import { IconWithToolTip } from 'components/common/icons';
import React, { useEffect, useState } from 'react';
import { scrollToTop } from 'util/page';

export default function ScrollToTop() {
  const [atTop, setAtTop] = useState(true);
  useEffect(() => {
    const updateAtTop = () => {
      setAtTop((document.documentElement.scrollTop ?? 0) < 10);
    };
    updateAtTop();
    window?.addEventListener('scroll', updateAtTop);
    return () => {
      window?.removeEventListener('scroll', updateAtTop);
    };
  }, []);
  return (
    <IconWithToolTip
      style={{
        cursor: 'pointer',
        transition: 'opacity 0.5s',
        opacity: Number(!atTop),
        pointerEvents: atTop ? 'none' : undefined,
      }}
      icon="arrowUp"
      toolTipProps={{ title: !atTop ? 'Scroll to top' : undefined }}
      onClick={scrollToTop}
    />
  );
}
