import React from 'react';
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { RumRoute } from 'util/datadog/components/RumRoute';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

export default function PublicRoute({
  component: Component,
  ...rest
}: PublicRouteProps) {
  return <RumRoute {...rest} render={(props) => <Component {...props} />} />;
}
