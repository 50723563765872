// config for restricting allowed tags in html content
import { useCallback } from 'react';
import DOMPurify from 'dompurify';
import validationConstants from 'constants/validationSchemaConstants';

// config for restricting allowed tags in html content
const sanitizeConf = {
  ALLOWED_TAGS: ['a', 'p', 'br', 'span'],
  ALLOWED_ATTR: ['href'],
};

export function useDomSafeText(dirtyText?: string) {
  const purify = useCallback(
    (dirtyTextLazy?: string) => {
      const target = dirtyTextLazy ?? dirtyText;
      if (!target) {
        return '';
      }

      return DOMPurify.sanitize(
        target.replace(validationConstants.NewLineRegex, '<br />'),
        sanitizeConf
      );
    },
    [dirtyText]
  );

  return {
    purify,
    domSafeText: purify(dirtyText),
  };
}
