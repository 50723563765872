import React, { Suspense } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { RumRoute } from 'util/datadog/components/RumRoute';
import routes from './constants/routes';
import { useUserRoleIncludes } from 'hooks/useUserRoles';
import Loader from 'components/utilities/Loader';
import { useRoundtableAuth0 } from './services/auth/hooks/useRoundtableAuth0';

interface PrivateRouteProps extends RouteProps {
  // forces component to be required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allow whatever props for now
  component: React.ComponentType<any>;
  isAdminRouteOnly?: boolean;
}

export default function PrivateRoute({
  component: Component,
  isAdminRouteOnly,
  children,
  ...rest
}: PrivateRouteProps) {
  const { isAuthenticated, isLoading } = useRoundtableAuth0();
  const isAdmin = useUserRoleIncludes('Admin');

  return (
    <RumRoute
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <Loader sx={{ height: '100%', alignItems: 'center' }} />;
        }

        if (isAdminRouteOnly && !isAdmin) {
          return (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }

        if (isAuthenticated) {
          return (
            <Suspense fallback={<Loader />}>
              <Component {...props} />
              {children}
            </Suspense>
          );
        }

        return (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
