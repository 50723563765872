import React from 'react';
import { Helmet } from 'react-helmet-async';
import rttBanner from 'assets/images/roundtable-mixed-colors-wide.png';

export default function MetaTags(props: {
  title: string;
  description: string;
  url?: string;
  type?: string;
  image?: string;
}) {
  return (
    <Helmet>
      <title>{props.title}</title>

      {/* Primary */}
      <meta name="title" content={props.title} />
      <meta name="description" content={props.description} />
      <meta
        name="keywords"
        content="numismatics, trading, coins, collectables, social, private, groups"
      />

      {/* -- Open Graph / Facebook --> */}
      <meta property="og:type" content={props.type ?? 'website'} />
      <meta property="og:url" content={props.url ?? window.location.href} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image ?? rttBanner} />

      {/* Twitter */}
      <meta property="twitter:card" content={props.type ?? 'website'} />
      <meta
        property="twitter:url"
        content={props.url ?? window.location.href}
      />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content={props.image ?? rttBanner} />
    </Helmet>
  );
}
