import { useCallback, useEffect, useState } from 'react';
import { useDomSafeText } from './useDomSafeText';
import { UserId } from 'src/services/userService/models/user.model';
import { CompanyId } from 'src/services/companyService/models/company.model';

export type MentionOption<Id extends UserId | CompanyId> = {
  id: Id;
  display: string;
  rawText: string;
};

export const USER_TAG_FORMAT = '@users:[__display__](__id__)';
export const COMPANY_TAG_FORMAT = '@companies:[__display__](__id__)';

// config rules for parsing mentions within text (should match the above formats)
const regEx = {
  userMention: /@users:\[(?<display>[\w|\s]*)]\((?<id>[0-9]*)\)/gm,
  companyMention:
    /@companies:\[(?<display>[\w|\s.&!@#%^*()]*)]\((?<id>[0-9]*)\)/gm,
} as const;

function parseMention({
  matcher,
  text,
}: {
  matcher: 'userMention';
  text: string;
}): MentionOption<UserId>[];
function parseMention({
  matcher,
  text,
}: {
  matcher: 'companyMention';
  text: string;
}): MentionOption<CompanyId>[];
function parseMention<Id extends UserId | CompanyId>({
  matcher,
  text,
}: {
  matcher: keyof typeof regEx;
  text: string;
}): MentionOption<Id>[] {
  const matchList = Array.from((text ?? '').matchAll(regEx[matcher]));

  return matchList.flatMap((match) => {
    const id = match?.groups?.id;
    const display = match?.groups?.display;

    if (id && display) {
      return {
        id: Number(id) as Id,
        display,
        rawText: match[0],
      };
    }
    return [];
  });
}

export const baseUserUrl = '../../members/{id}/table';
export const baseCompanyUrl = '../../companies/{id}/table';

export function useMentionParser() {
  return useCallback((text: string) => {
    const userTags = parseMention({ matcher: 'userMention', text });

    const companyTags = parseMention({
      matcher: 'companyMention',
      text,
    });
    return {
      userTags,
      companyTags,
    };
  }, []);
}

export function getMentionLabel(text: string, type: 'users' | 'companies') {
  return {
    id: text.match(/\((\d+)\)/)?.[1],
    label: text.replace(
      new RegExp(`@${type}:\\[([^\\]]+)\\]\\(\\d+\\)`, 'g'),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_, p1) => '@' + p1.replace(/([a-z])([A-Z])/g, '$1 $2')
    ),
  };
}

export function getTextWithParsedEntities(text?: string) {
  // remove all \n and then map for users/companies/emails
  return text?.replace(/\n/g, ' ').replace(
    /@users:\[([^\]]+)]|@companies:\[([^\]]+)]|([^\s@]+@[^\s@]+\.[^\s@]+)/g,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_, userPart, companyPart, emailPart) => {
      if (userPart) {
        return `@users:[${userPart.replace(/\s+/g, '')}]`;
      } else if (companyPart) {
        return `@companies:[${companyPart.replace(/\s+/g, '')}]`;
      } else if (emailPart) {
        return `@email:${emailPart}`;
      }
      return '';
    }
  );
}

export function useMentions(text: string) {
  const [textValue, setTextValue] = useState<string>(text ?? '');
  const [displayValue, setDisplayValue] = useState<string>('');
  const mentionParser = useMentionParser();
  const { purify } = useDomSafeText();

  useEffect(() => {
    if (text != textValue) {
      // handle updates to text prop
      setTextValue(text);
    }
  }, [text, textValue, setTextValue]);

  /**
   * Whenever textValue changes then update
   * - displayValue
   * - mentions
   */
  useEffect(() => {
    let finalDisplayValue = textValue;
    const { userTags, companyTags } = mentionParser(textValue);

    // translate users to links
    userTags.forEach((user) => {
      finalDisplayValue = finalDisplayValue.replace(
        user.rawText,
        `<a href='${baseUserUrl}'>${user.display}</a>`.replace(
          '{id}',
          `${user.id}`
        )
      );
    });

    // translate companies to links
    companyTags.forEach((company) => {
      finalDisplayValue = finalDisplayValue.replace(
        company.rawText,
        `<a href='${baseCompanyUrl}'>${company.display}</a>`.replace(
          '{id}',
          `${company.id}`
        )
      );
    });

    setDisplayValue(purify(finalDisplayValue));
  }, [textValue, purify, mentionParser]);

  return {
    textValue,
    setTextValue,
    displayValue,
  };
}
