import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useSnackbar } from 'components/notification/SnackbarNotification';
import routes from 'constants/routes';
import { RouterOutput } from '../util/trpc';
import { useRoundtableAuth0 } from 'services/auth/hooks/useRoundtableAuth0';
import { useOnboardingStatus } from 'services/user/useOnboardingStatus';

type OnboardingStep = Extract<
  RouterOutput['users']['onboarding']['status'],
  { isComplete: false }
>['nextStep'];

const steps = {
  setupProfile: 0,
  selectCompany: 1,
  joinGroup: 2,
} satisfies Record<OnboardingStep, number>;
const completeStep = 3;

const toastMessages = {
  setupProfile: 'You need to complete your profile before continuing.',
  selectCompany: 'You need to be a part of a company before continuing.',
  joinGroup: 'You need to be a part of a group before continuing.',
} satisfies Record<OnboardingStep, string>;

function SetupRedirect({
  step,
  messageOverride,
  showToast,
}: {
  step: keyof typeof steps;
  messageOverride?: string;
  showToast?: boolean;
}) {
  const { sendMessage } = useSnackbar();

  useEffect(() => {
    if (showToast) {
      const message = messageOverride ?? toastMessages[step];
      sendMessage({ message });
    }
  }, [showToast, messageOverride, sendMessage, step]);

  return <Redirect to={{ pathname: routes.SETUP, state: { step } }} />;
}

export function useSetupStep() {
  const { isAuthenticated, isLoading } = useRoundtableAuth0();
  const { data: status } = useOnboardingStatus({
    enabled: isAuthenticated && !isLoading,
  });
  return status?.isComplete
    ? completeStep
    : steps[status?.nextStep ?? 'setupProfile'];
}

export default SetupRedirect;
