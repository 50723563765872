import React, { useCallback } from 'react';
import { match } from 'ts-pattern';
import {
  Autocomplete,
  Box,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconWithToolTip } from '../icons';
import { RoundedListItem } from '../../views/Sales/RoundedListItem';
import { RouterOutput, trpc } from 'util/trpc';
import { HandleQueryStates } from '../../utilities/HandleQueryStates';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';
import { CommonSearch, SearchProps } from '../../utilities/CommonSearch';
import { useHighlightedText } from 'hooks/useHighlightedText';
import { useMentions } from 'hooks/useMentions';
import useDebounce from 'hooks/useDebounce';
import CommonModal from '../../utilities/CommonModal';
import { Theme } from '@mui/material/styles';
import { GroupId } from 'src/services/groupService/models/group.model';
import { useGroup } from '../../../services/groups/useGroup';

type SearchOption = RouterOutput['global']['search'][number];

const MaxBox = styled(Box)({
  maxHeight: 45,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // force max lines to be 2
  display: '-webkit-inline-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
});

const typeToIcon = {
  discussion: 'discussionPost',
  sale: 'salePost',
  group: 'groups',
  company: 'company',
  user: 'profile',
} as const;

function PostPrimaryText({ groupId }: { groupId: GroupId }) {
  const groupResponse = useGroup(groupId);
  return (
    <Box display="flex" justifyContent="space-between">
      <HandleQueryStates
        response={groupResponse}
        loaderAs="skeleton"
        SkeletonProps={{ width: '80%' }}
      >
        {({ response }) => {
          return (
            <Typography variant="body1">{response.data.data.name}</Typography>
          );
        }}
      </HandleQueryStates>
    </Box>
  );
}

const OptionContent = React.memo(
  function OptionContent({
    option,
    inputValue,
    onSelect,
  }: {
    inputValue: string;
    option: SearchOption;
    onSelect(): void;
  }): React.ReactElement {
    const history = useHistory();

    const { displayValue } = useMentions(option.description);
    const parts = useHighlightedText(displayValue, inputValue);

    const searchText = (
      <MaxBox>
        {parts.map((part, index) => (
          <Typography
            key={index}
            variant="body2"
            sx={{
              fontWeight: part.highlight ? 700 : 400,
              display: 'inline',
              wordBreak: 'break-word',
            }}
            dangerouslySetInnerHTML={{ __html: part.text }}
          />
        ))}
      </MaxBox>
    );

    return match(option)
      .with(
        {
          type: 'post',
        },
        (o) => {
          return (
            <RoundedListItem
              primaryText={<PostPrimaryText groupId={o.groupId} />}
              secondaryText={searchText}
              icon={<IconWithToolTip icon={typeToIcon[o.postType]} />}
              onClick={() => {
                onSelect();
                history.push(
                  `${routes.GROUP_FEED.replace(
                    ':id',
                    `${o.groupId}`
                  )}?post_id=${o.postId}`
                );
              }}
            />
          );
        }
      )
      .with({ type: 'user' }, (o) => {
        return (
          <RoundedListItem
            primaryText={searchText}
            icon={<IconWithToolTip icon={typeToIcon[o.type]} />}
            onClick={() => {
              history.push(routes.MEMBER_TABLE.replace(':id', `${o.userId}`));
            }}
          />
        );
      })
      .with({ type: 'company' }, (o) => {
        return (
          <RoundedListItem
            primaryText={searchText}
            icon={<IconWithToolTip icon={typeToIcon[o.type]} />}
            onClick={() => {
              history.push(
                routes.COMPANY_TABLE.replace(':id', `${o.companyId}`)
              );
            }}
          />
        );
      })
      .with({ type: 'group' }, (o) => {
        return (
          <RoundedListItem
            primaryText={searchText}
            icon={<IconWithToolTip icon={typeToIcon[o.type]} />}
            onClick={() => {
              history.push(routes.GROUP_FEED.replace(':id', `${o.groupId}`));
            }}
          />
        );
      })
      .exhaustive();
  },
  (o, n) =>
    o.option.description === n.option.description &&
    o.inputValue === n.inputValue
);

function useAppSearch() {
  const [queryValue, setQueryValue] = React.useState<string>('');
  const debouncedQuery = useDebounce(queryValue, 500);
  const queryIsNotEmpty = debouncedQuery.trim().length > 1;

  const searchResponse = trpc.global.search.useQuery(
    { queryString: debouncedQuery, limit: 20 },
    { enabled: queryIsNotEmpty }
  );

  return {
    isLoading: searchResponse.isLoading && queryIsNotEmpty,
    options: searchResponse.isSuccess ? searchResponse.data : [],
    queryValue,
    onQueryValueChange: (value?: string) => setQueryValue(value ?? ''),
  };
}

function Search({
  variant = 'lightBlueSearch',
  size = 'large',
  onSelect,
}: {
  variant?: SearchProps['searchClass'];
  size?: 'small' | 'large';
  onSelect(): void;
}) {
  const { onQueryValueChange, isLoading, options } = useAppSearch();

  return (
    <Autocomplete
      sx={size === 'small' ? { width: '95%' } : undefined}
      fullWidth
      options={options}
      loading={isLoading}
      noOptionsText="No results found"
      onInputChange={(_e, value) => onQueryValueChange(value)}
      groupBy={(option) => option.type}
      ListboxProps={{ sx: { height: '100%', maxHeight: '100vh' } }}
      renderInput={(params) => (
        <CommonSearch
          searchClass={variant}
          iconPosition="startAdornment"
          loading={isLoading}
          {...params}
        />
      )}
      getOptionLabel={(option) => option.description}
      renderOption={(_props, option, { inputValue }) => {
        return (
          <Box key={option.description} m={1} maxHeight={100}>
            <OptionContent
              option={option}
              inputValue={inputValue}
              onSelect={onSelect}
            />
          </Box>
        );
      }}
    />
  );
}

export default function GlobalSearchAutoComplete({
  variant,
}: {
  variant?: SearchProps['searchClass'];
}): React.ReactElement {
  const isMdOrSmaller = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  const [showSearch, setShowSearch] = React.useState(false);

  const closeModal = useCallback(() => setShowSearch(false), [setShowSearch]);

  if (isMdOrSmaller) {
    return (
      <>
        <IconWithToolTip icon="search" onClick={() => setShowSearch(true)} />
        <CommonModal
          title="Search"
          open={showSearch}
          onCloseModal={closeModal}
          aria={{
            'aria-labelledby': 'search-application',
            'aria-describedby': 'search-application',
          }}
        >
          <Box display="flex" mx={4} justifyContent="center">
            <Search
              variant="darkBorderSearch"
              size="small"
              onSelect={closeModal}
            />
          </Box>
        </CommonModal>
      </>
    );
  }

  return <Search variant={variant} onSelect={closeModal} />;
}
