import english from '../lang/en-US.json';

const messages: { [key: string]: Record<string, string> } = {
  en: english,
};

const lang = navigator.language.split(/[-_]/)[0];

export default {
  lang,
  messages,
};
