import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { useThemeStyles } from '../styles';
import { theme } from '../../theme';

export default function FeedBox({
  multicolorBackground,
  ...props
}: BoxProps & { multicolorBackground?: boolean }) {
  const themeClasses = useThemeStyles();

  const overrides = multicolorBackground
    ? { className: themeClasses.multicolorBackground }
    : undefined;

  return (
    <Box
      borderRadius="10px"
      boxShadow="0px 3px 6px #0000000D"
      mb={1}
      bgcolor={theme.palette.common.white}
      {...props}
      {...overrides}
    />
  );
}
