import React, { SyntheticEvent } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import useNotifications from 'services/notifications/useNotifications';
import NotificationButton from './NotificationButton';
import PopoverNotifications from './PopoverNotifications';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'constants/routes';

export default function AppNotifications() {
  const history = useHistory();
  const location = useLocation();
  const [popoverAnchor, setPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const { unreadTotal } = useNotifications({
    shouldFetchNotifications: Boolean(popoverAnchor),
    fetchAll: false,
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (location.pathname.includes(routes.NOTIFICATIONS)) return;
    if (isMobile) {
      history.push(routes.NOTIFICATIONS);
      return;
    }
    setPopoverAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchor(null);
  };

  return (
    <>
      <NotificationButton
        onClick={handleClick}
        size="medium"
        badgeContent={unreadTotal ?? 0}
        showBadge={unreadTotal == null || unreadTotal === 0}
      />
      {!isMobile && (
        <PopoverNotifications
          anchor={popoverAnchor}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
