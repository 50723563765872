import React, { useState } from 'react';
import { Menu } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';

type DrawerMenuProps = {
  children: React.ReactNode;
};

export default function DrawerMenu({ children }: DrawerMenuProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button size="large" onClick={handleClick}>
        <Menu />
      </Button>
      <Drawer
        open={open}
        anchor="right"
        onClose={handleClick}
        sx={{ '& .MuiBox-root': { padding: '10px' } }}
      >
        {children}
      </Drawer>
    </>
  );
}
