import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { RouterOutput } from '../../../util/trpc';
import { useTemplateContent } from './useTemplateContent';
import { getContentMatchers } from './getContentMatchers';
import { IconWithToolTip } from '../../common/icons';

export type Notification = RouterOutput['notifications']['get']['data'][0];

function TitleWithUserName({
  title,
  createdAt,
}: {
  title: React.ReactNode;
  createdAt: React.ReactNode;
}) {
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      {typeof title === 'string' ? (
        <Typography variant="subtitle2">{title}</Typography>
      ) : (
        title
      )}
      {typeof createdAt === 'string' ? (
        <Typography variant="caption">{createdAt}</Typography>
      ) : (
        createdAt
      )}
    </Box>
  );
}

function Title({ notification }: { notification: Notification }) {
  const hasRule = 'ruleId' in notification.metaData;
  const relativeCreated = DateTime.fromISO(notification.createdAt).toRelative();
  return (
    <TitleWithUserName
      title={notification.title}
      createdAt={
        hasRule ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconWithToolTip
              fontSize="small"
              icon="automations"
              toolTipProps={{ title: 'Automation' }}
              sx={{ pr: 0.5 }}
            />
            <Typography variant="subtitle2">{relativeCreated}</Typography>
          </Box>
        ) : (
          relativeCreated
        )
      }
    />
  );
}

function SubTitle({ notification }: { notification: Notification }) {
  const { body } = notification;
  const subtitleContent = useTemplateContent(
    [body],
    getContentMatchers(notification)
  );

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Box display="flex" gap={0.3} flexWrap="wrap">
          {subtitleContent.map((content, index) => {
            return typeof content === 'string' ? (
              <Typography key={`${content}-${index}`} variant="body2">
                {content}
              </Typography>
            ) : (
              content
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}

export function getNotificationItemProps(notification: Notification) {
  return {
    title: <Title notification={notification} />,
    subTitle: <SubTitle notification={notification} />,
  };
}
