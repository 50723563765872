export const palette = {
  primary: {
    main: '#074d93',
    dark: '#005292',
    light: '#53A6C3',
  },
  secondary: {
    main: '#4F34E1', // todo: change to #005552
    dark: '#2F95B6',
    light: '#E20062',
  },
  offWhite: {
    main: '#605F5F',
    dark: '#D8D8D8',
    light: '#EEEEEE',
  },
};

const colors = {
  ...palette,
  grey: {
    main: '#707070',
    light: '#00000014',
    dark: '#8A8A8A',
    matte: '#EBEBEB',
    silver: '#939393',
    fog: '#d4d4d4',
    cloudy: '#074D931A',
    charcoal: '#4A4A4AB3',
    pearlRiver: '#ffffff26',
    archroma: '#F2F2F2',
  },
  green: '#00AA1C',
  royalBlue: '#011C38',
  black: '#000',
  white: '#fff',
  blue: {
    active: 'rgba(0,82,146,0.29)',
    faded: '#074D9314',
  },
  purple: {
    active: 'rgba(104,82,243,0.5)',
    faded: 'rgba(200,192,235,0.71)',
  },
};

export default colors;
