import { trpc } from 'util/trpc';
import { GroupId } from 'src/services/groupService/models/group.model';
import { useBindToRequestsEvent } from '../notifications/useBindToChannelEvent';

export function useGroupMemberCount({ groupId }: { groupId: GroupId }) {
  const countResponse = trpc.groups.members.count.useQuery(
    { groupId },
    { staleTime: 1000 * 60 * 60 }
  );

  useBindToRequestsEvent('groupRequest:updated', () => {
    countResponse.refetch();
  });
  return countResponse;
}
