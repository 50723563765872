import React from 'react';
import { Link, Grid, Typography, useMediaQuery, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import headerBg from 'assets/images/background-mixedcolors.jpg';

import HeaderIcons from 'components/views/Layout/HeaderIcons';
import ContactEmailLink from 'components/common/ContactEmailLink';

import config from '../../../config/config';
import useActiveUserId from 'services/user/useActiveUserId';
import { useUserRoleIncludes } from 'hooks/useUserRoles';
import useLang from 'hooks/useLang';
import { cx } from 'util/classnames';
import { useHeaderTabs } from './useHeaderTabs';

const useStyles = makeStyles((theme) => ({
  appBar: {
    opacity: 0.86,
  },
  footerDesc: {
    color: theme.palette.grey[400],
    textDecoration: 'none',
    cursor: 'pointer',
  },
  anchorMargin: {
    padding: '0px 10px',
    borderRight: '1px solid',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  footerBg: {
    backgroundImage: `url(${headerBg})`,
    width: '100%',
    height: 'auto',
    paddingTop: 8,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 5,
  },
}));

function InfoFooter() {
  const { f } = useLang();
  const styleClasses = useStyles();
  const isAdmin = useUserRoleIncludes('Admin');
  const shouldShowVersion = isAdmin || config.appEnv !== 'production';
  const appVersion = config.version?.slice(0, 8) ?? config.appEnv;

  return (
    <AppBar
      position="relative"
      className={styleClasses.appBar}
      component="div"
      color="default"
    >
      <Box p={2}>
        <Grid container>
          <Grid item md={6}>
            <Typography align="left" paragraph={false}>
              © {new Date().getFullYear()} {f('app.footer.copy_right')}
              {shouldShowVersion && (
                <Link
                  onClick={() =>
                    appVersion && navigator.clipboard.writeText(appVersion)
                  }
                  className={`${styleClasses.footerDesc} ${styleClasses.anchorMargin}`}
                  underline="hover"
                >
                  | {appVersion}
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography align="right" paragraph={false}>
              <ContactEmailLink
                subject="Contact Support"
                className={cx(
                  styleClasses.footerDesc,
                  styleClasses.anchorMargin
                )}
              >
                Contact
              </ContactEmailLink>
              <Link
                href="https://roundtabletrading.com/terms-and-conditions"
                target="_blank"
                className={cx(
                  styleClasses.footerDesc,
                  styleClasses.anchorMargin
                )}
                underline="hover"
              >
                Terms of Service
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
}

const Footer = ({ variant }: { variant?: 'info' | 'actions' }): JSX.Element => {
  const styleClasses = useStyles();
  const userId = useActiveUserId();
  const headerTabs = useHeaderTabs(userId);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  if (variant === 'info' || userId == null) {
    return <InfoFooter />;
  }

  if (isMobile || variant === 'actions') {
    // todo: if user is not found... maybe we should redirect to login?
    return (
      <Box className={styleClasses.footerBg}>
        <HeaderIcons tabs={headerTabs} />
      </Box>
    );
  }

  return <InfoFooter />;
};

export default Footer;
