import * as React from 'react';
import { Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';

type Action =
  | { type: 'show_message'; nextMessage: NextMessage }
  | { type: 'clear_message' };
type Dispatch = (action: Action) => void;
type SnackbarProviderProps = { children: React.ReactNode };

interface Message extends SnackbarProps {
  message: string;
  severity: 'error' | 'info' | 'warning' | 'success';
  open: boolean;
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right' | 'center';
  timeout: number;
  action?: React.ReactNode;
}

type ActiveMessage = Omit<Message, 'message'> & {
  message: Message['message'] | null;
  uuid: string | null;
  open: boolean;
};

type NextMessage = { message: string; uuid?: string; open?: never } & Partial<
  Omit<Message, 'open'>
>;

const initialState: ActiveMessage = {
  message: null,
  uuid: null,
  severity: 'info',
  open: false,
  vertical: 'top',
  horizontal: 'center',
  timeout: 5000,
};

const SnackbarNotificationState = React.createContext<
  ActiveMessage | undefined
>(undefined);

const SnackbarNotificationDispatch = React.createContext<Dispatch | undefined>(
  undefined
);

function snackbarReducer(previousActiveMessage: ActiveMessage, action: Action) {
  switch (action.type) {
    case 'show_message':
      return {
        ...previousActiveMessage,
        ...action.nextMessage,
        open: true,
      };

    case 'clear_message':
      return initialState;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-extra-semi
      ((_unhandled: never) => null)(action);
      throw new Error(`Unhandled action=${action}`);
  }
}

function SnackbarNotificationProvider({
  children,
}: SnackbarProviderProps): React.ReactElement {
  const [activeMessages, dispatch] = React.useReducer(
    snackbarReducer,
    initialState
  );
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = { activeMessage, dispatch };

  return (
    <SnackbarNotificationState.Provider value={activeMessages}>
      <SnackbarNotificationDispatch.Provider value={dispatch}>
        {children}
      </SnackbarNotificationDispatch.Provider>
    </SnackbarNotificationState.Provider>
  );
}

function useSnackbarContext(): ActiveMessage {
  const context = React.useContext(SnackbarNotificationState);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }

  return context;
}

function useSnackbarDispatch(): Dispatch {
  const dispatch = React.useContext(SnackbarNotificationDispatch);
  if (dispatch === undefined) {
    throw new Error(
      'useSnackbarDispatch must be used within a SnackbarNotificationProvider'
    );
  }

  return dispatch;
}

export { SnackbarNotificationProvider, useSnackbarContext };

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  uuid: {
    paddingTop: theme.spacing(1),
    display: 'block',
    textAlign: 'right',
    ...theme.typography.caption,
  },
}));

const usePositionStyles = makeStyles((theme) => ({
  top: {
    [theme.breakpoints.down('sm')]: {
      top: '60px',
    },
    top: '75px',
  },
  bottom: {
    [theme.breakpoints.down('sm')]: {
      bottom: '52px',
    },
    bottom: '60px',
  },
}));

const useMessage = (): ActiveMessage & {
  handleClose: () => void;
} => {
  const activeMessage = useSnackbarContext();
  const dispatch = useSnackbarDispatch();

  const handleClose = () => {
    dispatch({ type: 'clear_message' });
  };

  return { ...activeMessage, handleClose };
};

export function useSnackbar(): { sendMessage(nextMessage: NextMessage): void } {
  const dispatch = useSnackbarDispatch();
  const sendMessage = React.useCallback(
    (nextMessage) => dispatch({ type: 'show_message', nextMessage }),
    [dispatch]
  );

  return {
    sendMessage,
  };
}

export function SnackbarNotification(): React.ReactElement | null {
  const {
    open,
    message,
    severity,
    vertical,
    horizontal,
    timeout,
    handleClose,
    action,
    uuid,
    ...props
  } = useMessage();
  const classes = useStyles({ severity });
  const classesForPosition = usePositionStyles();

  if (message == null) {
    // to prevent a snackbar from flashing, just return nothing
    return null;
  }

  return (
    <div className={classes.root}>
      <Snackbar
        className={classesForPosition[vertical]}
        open={open}
        autoHideDuration={timeout}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        {...props}
      >
        <Alert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={severity}
          action={action}
        >
          {message}
          {uuid && <span className={classes.uuid}>{uuid}</span>}
        </Alert>
      </Snackbar>
    </div>
  );
}
