import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CommonList from 'components/utilities/CommonList';
import { HandleQueryStates } from 'components/utilities/HandleQueryStates';
import useNotifications from 'services/notifications/useNotifications';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useGetNotificationItem } from './notifications/useGetNotificationItem';
import { LoadingSkeletons } from '../common/LoadingSkeletons';

type NotificationsListProps = {
  shouldFetchNotifications: boolean;
  onSeeAllClick?: () => void;
};

const useStyles = makeStyles(() => ({
  link: { cursor: 'pointer' },
  button: { all: 'unset', color: '#074d93' },
}));

function ViewAllNotifications({ onClick }: { onClick?: () => void }) {
  return (
    <Typography variant="body1" color="textSecondary">
      <Link onClick={onClick} to={`${routes.NOTIFICATIONS}`}>
        See all notifications
      </Link>
    </Typography>
  );
}

export default function NotificationsList({
  shouldFetchNotifications,
  onSeeAllClick,
}: NotificationsListProps) {
  const classes = useStyles();
  const {
    notificationsResponse,
    onNotificationsRead,
    readNotifications,
    onNotificationChange,
  } = useNotifications({
    shouldFetchNotifications: shouldFetchNotifications,
    fetchAll: false,
  });

  const getNotificationItem = useGetNotificationItem({
    onChange: onNotificationChange,
  });

  return (
    <HandleQueryStates
      response={notificationsResponse}
      loaderAs="skeleton"
      skeleton={<LoadingSkeletons count={3} boxProps={{ minWidth: '35vw' }} />}
    >
      {({ response }) => {
        const notifications = response.data.pages.flatMap((p) => p.data);

        if (notifications.length === 0) {
          return (
            <Box
              px={4}
              py={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <ViewAllNotifications />
              <Typography variant="body1" color="textSecondary">
                ✅ You have no notifications
              </Typography>
            </Box>
          );
        }

        return (
          <Box
            minWidth="35vw"
            maxHeight="40vh"
            overflow="auto"
            id="scrollable-notifications-list"
          >
            <Box
              px={4}
              pt={1}
              display="flex"
              justifyContent="space-between"
              minWidth="fit-content"
            >
              <ViewAllNotifications onClick={onSeeAllClick} />
              <Typography variant="body1" color="textSecondary" align="right">
                <Button
                  onClick={() => onNotificationsRead()}
                  className={classes.button}
                >
                  Mark all as read
                </Button>
              </Typography>
            </Box>
            <CommonList
              next={response.fetchNextPage}
              hasMore={response.hasNextPage}
              isLoading={notificationsResponse.isLoading}
              endMessage={<ViewAllNotifications />}
              scrollableTarget="scrollable-notifications-list"
              items={notifications.map((notification) =>
                getNotificationItem({
                  notification,
                  isChecked:
                    readNotifications?.includes(notification.id) ?? false,
                })
              )}
            />
          </Box>
        );
      }}
    </HandleQueryStates>
  );
}
