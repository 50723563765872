import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollRestoration() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollStored = sessionStorage.getItem(storeKey(pathname));
    const scroll = scrollStored ? Number(scrollStored) : null;
    if (scroll && Number.isFinite(scroll)) {
      window.scrollTo({
        // @ts-expect-error instant is valid, fixed in typescript 5.4
        behavior: 'instant',
        top: scroll,
      });
    }
  }, [pathname]);

  useEffect(() => {
    const onScroll = debounce(() => {
      sessionStorage.setItem(storeKey(pathname), window.scrollY.toString());
    }, 200);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [pathname]);
}

function storeKey(pathname: string) {
  return `scroll:${pathname}`;
}
