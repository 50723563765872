import { Box } from '@mui/material';
import React, { PropsWithChildren, useEffect, useRef } from 'react';

type Props = PropsWithChildren<{
  onHeightChange: (height: number) => void;
  at: 'top' | 'bottom';
}>;

export default function FixedHeightObservingBox({
  onHeightChange,
  at,
  children,
}: Props) {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      onHeightChange(entry.contentRect.height);
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [onHeightChange]);

  return (
    <Box
      ref={ref}
      sx={{
        position: 'fixed',
        top: at === 'top' ? 0 : undefined,
        bottom: at === 'bottom' ? 0 : undefined,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      {children}
    </Box>
  );
}
