import { useActiveUser } from './useActiveUser';
import { useRoundtableAuth0UserAudienceAttribute } from 'services/auth/hooks/useRoundtableAuth0UserAudienceAttribute';
import { UserId } from 'src/services/userService/models/user.model';

/**
 * looks for the users id on their db record or auth0 claim attributes
 * in some cases the user might not be found such as:
 *   1. initial app render on a route
 *   2. new user signup - during profile creation
 *   3. user's session has expired
 */
export default function useActiveUserId(): UserId | null {
  const auth0 = useRoundtableAuth0UserAudienceAttribute();
  const user = useActiveUser({
    // prevent io if we have the user id
    enabled: auth0.get('userId') == null,
  });

  /**
   * get user id from auth0 or from the actual db
   */
  return auth0.get('userId') ?? user?.data?.id ?? null;
}
