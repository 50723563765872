import { trpc } from '../../util/trpc';
import { useBindToGroupEvents } from '../notifications/useBindToEvents';
import { PostId } from 'src/services/postService/models/post.model';

export function usePost(postId: PostId) {
  const postResponse = trpc.posts.getByIdOrThrow.useQuery({
    id: postId,
  });

  const post = postResponse?.data?.data;

  useBindToGroupEvents(
    post?.groupId ?? null,
    ({ data }) => {
      if (post?.id !== data.id) {
        /**
         * the post owner deleted their own post, don't refetch since we have UI to allow undoing
         */
        return;
      }
      postResponse.refetch();
    },
    ['post:updated', 'post:removed']
  );

  return postResponse;
}
