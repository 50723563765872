import { useSnackbar } from 'components/notification/SnackbarNotification';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';

interface PwaContextProps {
  children: ReactNode;
}

enum UserChoice {
  ACCEPTED = 'accepted',
  DISMISSED = 'dismissed',
}

interface BeforeInstallPromptEvent extends Event {
  readonly userChoice: Promise<{
    outcome: UserChoice;
    platform: string;
  }>;

  prompt(): Promise<void>;
}

interface PwaContextValues {
  installPrompt: () => Promise<void>;
  canInstall: boolean;
  isInstalled: boolean;
  isStandalone: boolean;
}

const PwaContext = createContext<PwaContextValues | undefined>(undefined);

export const usePwaContext = (): PwaContextValues => {
  const context = useContext(PwaContext);
  if (!context) {
    throw new Error('usePwaContext must be used within a PwaContextProvider');
  }
  return context;
};

export const PwaContextProvider = ({ children }: PwaContextProps) => {
  const [installPrompt, setInstallPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [canInstall, setCanInstall] = useState<boolean>(false);
  const [isInstalled, setInstalled] = useState<boolean>(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(
    window.matchMedia('(display-mode: standalone)').matches
  );

  const { sendMessage } = useSnackbar();

  useEffect(() => {
    const beforePromptEvent = (event: Event) => {
      event.preventDefault();
      setInstallPrompt(event as BeforeInstallPromptEvent);
      setCanInstall(true);
    };

    const installEvent = () => {
      setInstalled(true);
      setIsStandalone(true);
    };

    window.addEventListener('beforeinstallprompt', beforePromptEvent);
    window.addEventListener('appinstalled', installEvent);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforePromptEvent);
      window.removeEventListener('appinstalled', installEvent);
    };
  }, []);

  const handleInstallPrompt = useCallback(async () => {
    if (installPrompt) {
      try {
        await installPrompt.prompt();
      } catch (e) {
        sendMessage({
          message:
            'Something went wrong installing the app. Please try again later.',
          severity: 'warning',
        });
      }
    }
  }, [installPrompt, sendMessage]);

  const contextValues: PwaContextValues = {
    installPrompt: handleInstallPrompt,
    canInstall,
    isInstalled,
    isStandalone,
  };

  return (
    <PwaContext.Provider value={contextValues}>{children}</PwaContext.Provider>
  );
};
