// import { applyMiddleware, createStore } from 'redux'
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

/**
 * Redux store setup with reducers
 */

const composeMiddleware =
  process.env.REACT_APP_ENV !== 'production'
    ? // @ts-expect-error -- this is present on window
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose
    : compose;

export const store = createStore(
  reducers,
  {},
  composeMiddleware(applyMiddleware(thunk))
);
