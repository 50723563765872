import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import config from 'config/config';
import type { AppState } from '@auth0/auth0-react/dist/auth0-provider';

export default function Auth0ProviderWithHistory({
  children,
}: {
  children: React.ReactNode;
}) {
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={config.auth.redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={config.auth.audience}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
