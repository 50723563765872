import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import routes from './constants/routes';
import PrivateRoute from './PrivateRoute';

import Layout from 'components/views/Layout';
import { useRoundtableAuth0 } from './services/auth/hooks/useRoundtableAuth0';
import { useOnboardingStatus } from './services/user/useOnboardingStatus';
import SetupRedirect from './hooks/useSetupStep';
import { useUpdateGroupChannelSubscriptions } from './services/groups/useUpdateGroupChannelSubscriptions';
import { useLoginUserForPushNotifications } from './services/notifications/pushNotifications';
import { useActiveUser } from './services/user/useActiveUser';
const CompanyMapView = React.lazy(
  () => import('./components/views/CompanyMapView')
);
const RulesView = React.lazy(
  () => import('./components/views/RulesView/RulesView')
);

const GroupDetails = React.lazy(() => import('components/views/GroupDetails'));
const Logout = React.lazy(() => import('./components/auth/Logout'));
const Profile = React.lazy(
  () => import('components/views/Profile/ProfileRoot')
);
const Company = React.lazy(
  () => import('components/views/Profile/ProfileCompany')
);
const MyGroup = React.lazy(
  () => import('components/views/Discover/GroupsListView')
);
const GroupFeed = React.lazy(() => import('components/views/GroupFeed'));
const CompaniesListView = React.lazy(
  () => import('components/views/CompanyListView')
);
const CompanyTableView = React.lazy(
  () => import('components/views/MyTableView/CompanyMyTableView')
);
const ProfileSales = React.lazy(
  () => import('components/views/Profile/ProfileSales')
);
const AccountSetup = React.lazy(
  () => import('components/views/AccountSetup/AccountSetup')
);
const MembersView = React.lazy(
  () => import('components/views/MembersView/MembersView')
);

const MemberTableView = React.lazy(
  () => import('components/views/MembersView/MemberTableView')
);

const MainFeed = React.lazy(() => import('components/views/Feed/MainFeed'));

const MessagingView = React.lazy(() => import('components/views/Messaging'));

/**
 * an "empty" component that will reload the user's channel subscriptions
 * this was causing infinite loops when used in the PrivateRoutes component
 * so we "sidecar" it as a component for now
 */
function ReLoadChannelSubscriptions() {
  const { isAuthenticated, isLoading } = useRoundtableAuth0();
  /**
   * listen for group request updates and subscribe to new groups on update
   */
  useUpdateGroupChannelSubscriptions({
    enabled: isAuthenticated && !isLoading,
  });
  return null;
}

/**
 * an "empty" component that will reload the user's push notifications by userId
 * so we "sidecar" it as a component to avoid any render side effects
 */
function ListenForPushNotifications() {
  const setUserIdForPushNotifications = useLoginUserForPushNotifications();
  const user = useActiveUser();
  const publicId = user?.data?.publicId;

  useEffect(() => {
    if (publicId != null) {
      setUserIdForPushNotifications(publicId);
    }
  }, [setUserIdForPushNotifications, publicId]);
  return null;
}

export default function PrivateRoutes() {
  const { isAuthenticated, isLoading } = useRoundtableAuth0();
  const location = useLocation();

  const onboardingStatus = useOnboardingStatus({
    enabled: isAuthenticated && !isLoading,
  });

  if (!isAuthenticated && !isLoading) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN,
          state: { from: location },
        }}
      />
    );
  }

  if (location.pathname !== routes.SETUP) {
    if (onboardingStatus.data != null && !onboardingStatus.data?.isComplete) {
      return <SetupRedirect step={onboardingStatus.data.nextStep} />;
    }
  }

  return (
    <Route>
      <Layout>
        <ReLoadChannelSubscriptions />
        <ListenForPushNotifications />
        <Switch>
          <PrivateRoute
            path={routes.COMPANY_TABLE}
            component={CompanyTableView}
          />
          <PrivateRoute path={routes.GROUP_FEED} component={GroupFeed} />
          <PrivateRoute path={routes.GROUP_DETAIL} component={GroupDetails} />
          <PrivateRoute path={routes.MY_GROUPS} component={MyGroup} />

          <PrivateRoute path={routes.COMPANY} component={Company} />
          <PrivateRoute
            path={routes.COMPANIES}
            component={CompaniesListView}
            exact={true}
          />
          <PrivateRoute
            path={routes.COMPANIES_MAP}
            component={CompanyMapView}
          />
          <PrivateRoute path={routes.MEMBERS_SALES} component={ProfileSales} />
          <PrivateRoute path={routes.PROFILE} component={Profile} />
          <PrivateRoute path={routes.LOGOUT} component={Logout} />
          <PrivateRoute path={routes.SETUP} component={AccountSetup} />

          <PrivateRoute
            path={routes.MEMBER_TABLE}
            component={MemberTableView}
          />
          <PrivateRoute path={routes.MEMBERS} component={MembersView} />
          <PrivateRoute path={routes.AUTOMATIONS} component={RulesView} />
          <PrivateRoute path={routes.MESSAGING} component={MessagingView} />
          <PrivateRoute path={routes.HOME} component={MainFeed} exact />
        </Switch>
      </Layout>
    </Route>
  );
}
