import { useEffect } from 'react';
import type {
  Channel,
  ChannelEvent,
  ChannelEventData,
  GroupChannel,
  NotificationChannel,
  RequestChannel,
} from 'src/services/notificationService/channelsService/types';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { useBindToChannelLazily } from './useBindToChannelLazily';
import type { GroupId } from 'src/services/groupService/models/group.model';
import useActiveUserId from 'services/user/useActiveUserId';

type Options = {
  /**
   * ensure only 1 listener function is bound to the event globally
   */
  enforceSingleListener?: true;
  /**
   * wait for wrapping logic to enable
   */
  enabled?: boolean;
};

function useBindToChannelEvent<
  C extends Channel,
  E extends ChannelEvent<C>,
  Data extends ChannelEventData<C, E> = ChannelEventData<C, E>
>(
  // channel can be nullish in some cases, so we handle that in this hook and try to limit exposure to this option in the wrapping hooks
  channel: C | null,
  event: E,
  listener: (args: { event: E; data: Data }) => void,
  options?: Options
) {
  const bindEventListener = useBindToChannelLazily();
  const componentWillUnmount = useComponentWillUnmount();

  useEffect(() => {
    if (channel == null) {
      return;
    }

    if (options?.enabled === false) {
      return;
    }

    const boundEvent = bindEventListener(channel, {
      enforceSingleListener: options?.enforceSingleListener ?? false,
      eventName: event,
      listener: (data) => listener({ event, data: data as Data }),
    });

    // unbind on unmount
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- this is intentionally expected to change
      if (componentWillUnmount.current) {
        boundEvent.unbind();
      }
    };
  }, [
    bindEventListener,
    componentWillUnmount,
    event,
    channel,
    listener,
    options?.enforceSingleListener,
    options?.enabled,
  ]);
}

export function useBindToGroupEvent<
  E extends ChannelEvent<GroupChannel>,
  Data extends ChannelEventData<GroupChannel, E> = ChannelEventData<
    GroupChannel,
    E
  >
>(
  groupId: GroupId,
  event: E,
  listener: (args: { event: E; data: Data }) => void,
  options?: Options
) {
  return useBindToChannelEvent<GroupChannel, E, Data>(
    `private-group-${groupId}`,
    event,
    listener,
    options
  );
}
export function useBindToNotificationEvent<
  E extends ChannelEvent<NotificationChannel>,
  Data extends ChannelEventData<NotificationChannel, E> = ChannelEventData<
    NotificationChannel,
    E
  >
>(
  event: E,
  listener: (args: { event: E; data: Data }) => void,
  options?: Options
) {
  const activeUserId = useActiveUserId();
  return useBindToChannelEvent<NotificationChannel, E, Data>(
    activeUserId != null ? `private-notifications-${activeUserId}` : null,
    event,
    listener,
    options
  );
}
export function useBindToRequestsEvent<
  E extends ChannelEvent<RequestChannel>,
  Data extends ChannelEventData<RequestChannel, E> = ChannelEventData<
    RequestChannel,
    E
  >
>(
  event: E,
  listener: (args: { event: E; data: Data }) => void,
  options?: Options
) {
  const activeUserId = useActiveUserId();
  return useBindToChannelEvent<RequestChannel, E, Data>(
    activeUserId != null ? `private-requests-${activeUserId}` : null,
    event,
    listener,
    options
  );
}
