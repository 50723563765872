import { trpc } from '../../util/trpc';
import { useCallback } from 'react';

export function useClearOnboardingStatus() {
  const trpcContext = trpc.useContext();

  return useCallback(() => {
    trpcContext.users.onboarding.status.invalidate();
  }, [trpcContext]);
}

export function useOnboardingStatus({ enabled }: { enabled?: boolean } = {}) {
  const onboardingResponse = trpc.users.onboarding.status.useQuery(undefined, {
    // keep this data around since its so high up the render stack
    staleTime: 1000 * 60 * 60,
    enabled,
  });

  return onboardingResponse;
}
