import routes from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { Notification } from './getNotificationItemProps';
import useFuncDebounce from 'hooks/useFuncDebounce';

export default function useOnNotificationClick() {
  const history = useHistory();

  function onNotificationClick(notification: Notification) {
    if (notification.type === 'post') {
      switch (notification.metaData.type) {
        case 'comment:added':
        case 'comment:inline_tagged':
        case 'comment:company:inline_tagged':
        case 'comment:reaction_added':
        case 'comment:removed_by_admin':
        case 'reaction:added':
        case 'post:explicitly_tagged':
        case 'post:inline_tagged':
        case 'post:company:inline_tagged':
        case 'sale:status_changed':
        case 'sale:valid_purchase_attempt_created':
        case 'sale:sale_price_increase':
        case 'sale:counter_offer_accepted':
        case '@buyer:sale:purchased':
        case '@seller:sale:sold':
        case '@author:comment:added':
        case '@author:reaction:added':
          return history.push(
            routes.GROUP_FEED.replace(
              ':id',
              `${notification.metaData.groupId}?post_id=${notification.postId}`
            )
          );
        default:
          ((_handleAllOptions: never) => null)(notification.metaData);
          return;
      }
    }
    if (notification.type === 'company') {
      switch (notification.metaData.type) {
        case 'associate:joined':
          return history.push(
            routes.COMPANY_TABLE.replace(':id', `${notification.companyId}`)
          );
        default:
          ((_handleAllOptions: never) => null)(notification.metaData.type);
          return;
      }
    }
    if (notification.type === 'group') {
      switch (notification.metaData.type) {
        case 'member:requested':
          return history.push(
            routes.GROUP_FEED.replace(':id', `${notification.groupId}`)
          );
        case 'post:created':
          return history.push(
            routes.GROUP_FEED.replace(
              ':id',
              `${notification.groupId}?post_id=${notification.metaData.postId}`
            )
          );
        case 'post:removed_by_admin':
          return history.push(
            routes.GROUP_FEED.replace(':id', `${notification.groupId}`)
          );
        case 'group:approved':
          return history.push(
            routes.GROUP_FEED.replace(':id', `${notification.groupId}`)
          );
        case 'group:declined':
          return history.push(routes.MY_GROUPS);
        default:
          ((_handleAllOptions: never) => null)(notification.metaData);
          return;
      }
    }
    ((_handleAllOptions: never) => null)(notification);
    return;
  }

  return useFuncDebounce(onNotificationClick, 500);
}
