import React from 'react';
import Button, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import constants from '../../constants/storyBookConstants';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../theme/colors';
import { ClassNameMap } from '@mui/styles/withStyles';
import useLang from 'hooks/useLang';
import { CircularProgress } from '@mui/material';

// Style Logic for the component:
export const useStyles = makeStyles((theme) => ({
  roundtableButton: {
    backgroundColor: colors.primary.dark,
    borderRadius: '50px',
    padding: 8,
    color: theme.palette.common.white,
    border: '1px solid transparent',
    fontWeight: 500,
    fontSize: '12px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    transition: '0.4s',
    '& span': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: colors.primary.dark,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${colors.primary.dark}`,
      '& span': {
        color: colors.primary.dark,
      },
    },
  },

  outlinedButton: {
    color: colors.secondary.dark,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.secondary.dark}`,
    '& span': {
      color: colors.secondary.dark,
    },
  },
  secondaryErrorButton: {
    color: colors.secondary.dark,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.secondary.light}`,
    '& span': {
      color: colors.secondary.light,
    },
  },

  disableButton: {
    opacity: '0.8',
    cursor: 'not-allowed',
    color: theme.palette.common.white,
    backgroundColor: 'lightgray',
  },
  extraLarge: {
    padding: '10px 8px',
    width: '100%',
  },
  large: {
    padding: '10px 0px',
  },
  medium: {
    padding: '12px 32px',
  },
  small: {
    padding: '6px 34px',
  },

  commonSpinner: {
    width: '15px',
    height: '15px',
    marginLeft: '10px',
    color: '#000',
  },
}));

export type ButtonProps = Omit<MuiButtonProps, 'size'> & {
  /**
   * Provides the size of the button:
   */
  size?: 'extraLarge' | 'large' | 'medium' | 'small';
  /**
   * Provides textual value of the button:
   */
  label: string | React.ReactNode;
  /**
   * Disable your button from further interaction:
   */
  disabled?: boolean;
  /**
   * /**
   * outline your button from further interaction:
   */
  outlined?: boolean;
  /**
   * Provide link to the button for routing purposes:
   */
  href?: string;
  /**
   * A method that executes when the button is clicked:
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   * Variable that checks if component is being viewed in storybook or not:
   */
  storybookDemo?: boolean;
  loading?: boolean;
  /**
   * Variable user for show secondary error color :
   */
  secondaryError?: boolean;
  fullWidth?: boolean;
  type?: 'submit';
};

/**
 * Primary UI Button component for user interaction.
 */
export function CommonButton({
  size = constants.size['extraLarge'],
  label,
  disabled = false,
  outlined = false,
  storybookDemo = false,
  loading = false,
  secondaryError = false,
  fullWidth = false,
  ...props
}: ButtonProps) {
  const styleClasses: ClassNameMap = useStyles();
  const { f } = useLang();

  return (
    <Button
      disabled={disabled}
      className={`${styleClasses?.roundtableButton} ${styleClasses[size]} ${
        disabled && styleClasses.disableButton
      } ${outlined && styleClasses.outlinedButton} ${
        secondaryError && styleClasses.secondaryErrorButton
      } `}
      {...props}
      fullWidth={fullWidth}
    >
      {storybookDemo || typeof label !== 'string' ? label : f(label)}
      {/* we will seperate this loader in next sprint */}
      {loading ? (
        <CircularProgress className={styleClasses.commonSpinner} size="15" />
      ) : null}
    </Button>
  );
}
