import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import mixedColors from 'assets/images/background-mixedcolors.jpg';
import { RouterOutput } from 'util/trpc';
import CommonList from '../CommonList';
import GroupDetail from './GroupDetail';

type MyGroups = NonNullable<RouterOutput['groups']['getByUser']['data']>;

export default function MiniGroupList({
  groups,
  activeGroupId,
}: {
  groups: MyGroups;
  activeGroupId?: number | string;
}) {
  const history = useHistory();

  return (
    <CommonList
      items={groups.map((group) => ({
        id: group.id,
        isActive: group.id.toString() === activeGroupId,
        title: group.name,
        avatar: group.coverPicture ? group.coverPicture : mixedColors,
        subTitle: (
          <GroupDetail groupId={group.id} isPublic={group.isPublic === 1} />
        ),
        onClick: (item) => {
          history.push(routes.GROUP_FEED.replace(':id', item.id.toString()));
        },
      }))}
    />
  );
}
