import routes from 'constants/routes';

function getRequiredVariable(
  key:
    | 'REACT_APP_BASE_URL'
    | 'NODE_ENV'
    | 'REACT_APP_PUSHER_CHANNELS_KEY'
    | 'REACT_APP_PUSHER_CHANNELS_CLUSTER'
    | 'REACT_APP_AUTH0_DOMAIN'
    | 'REACT_APP_AUTH0_CLIENT_ID'
    | 'REACT_APP_ENV'
    | 'REACT_APP_SENDBIRD_ID'
    | 'REACT_APP_ONE_SIGNAL_APP_ID'
): string {
  const value = process.env[key];
  if (value == null) {
    throw new Error(`Missing environment variable: ${key}`);
  }
  return value;
}

const getConfig = () => {
  return {
    env: getRequiredVariable('NODE_ENV'),
    appEnv: getRequiredVariable('REACT_APP_ENV'),
    version: process.env.REACT_APP_GIT_SHA,
    trpcRefetchOnWindowFocus:
      process.env.REACT_APP_TRPC_REFETCH_ON_WINDOW_FOCUS,
    auth: {
      domain: getRequiredVariable('REACT_APP_AUTH0_DOMAIN'),
      clientId: getRequiredVariable('REACT_APP_AUTH0_CLIENT_ID'),
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
      redirectUri: `${window.location.origin}${routes.AUTH_CALLBACK}`,
    },
    pusher: {
      channelsKey: getRequiredVariable('REACT_APP_PUSHER_CHANNELS_KEY'),
      channelsCluster: getRequiredVariable('REACT_APP_PUSHER_CHANNELS_CLUSTER'),
      debug: process.env.REACT_APP_PUSHER_CHANNELS_DEBUG !== 'false',
    },
    oneSignal: {
      appId: getRequiredVariable('REACT_APP_ONE_SIGNAL_APP_ID'),
      debugLevel: process.env.REACT_APP_ONE_SIGNAL_DEBUG_LEVEL ?? 'info',
    },
    google: {
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
      scriptURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    },
    baseUrl: process.env.REACT_APP_BASE_URL,
    customerSupportEmail: process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL || '',

    tempUserDetail: {
      grantType: process.env.REACT_APP_GRANT_TYPE,
      clientId: process.env.REACT_APP_CLIENT_ID,
      userName: process.env.REACT_APP_USER_NAME,
      password: process.env.REACT_APP_PASSWORD,
      audience: process.env.REACT_APP_AUDIENCE,
    },
    datadog: {
      // required in deployed environments only
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    },
    publicDealerMapUrl: process.env.REACT_APP_PUBLIC_DEALER_MAP_LIST_URL || '',
    sendbird: {
      appId: getRequiredVariable('REACT_APP_SENDBIRD_ID'),
    },
    coinbidz: {
      show: process.env.REACT_APP_SHOW_COINBIDZ_LINK === 'true',
      url: process.env.REACT_APP_COINBIDZ_URL ?? 'https://coinbidz.com/',
    },
  } as const;
};

export default getConfig();
