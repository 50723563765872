import React, { useMemo } from 'react';
import { Box, List, Typography } from '@mui/material';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import routes from '../../constants/routes';
import { Link, useLocation } from 'react-router-dom';
import { SideListItem } from './PrimarySideBar';
import { IconWithToolTip } from '../common/icons';
import { HeaderMenuItem } from '../views/Layout/HeaderIcons';

const sideBarItems = [
  {
    link: routes.PROFILE,
    icon: <IconWithToolTip icon="profile" />,
    title: 'My Account',
  },
  {
    link: routes.NOTIFICATIONS,
    icon: <AccountCircle />,
    title: 'Notifications',
  },
  {
    link: routes.COMPANY,
    icon: <IconWithToolTip icon="company" />,
    title: 'Company',
  },
  {
    link: routes.PROFILE_SETTINGS,
    icon: <IconWithToolTip icon="settings" />,
    title: 'Settings',
  },
  {
    link: routes.AUTOMATIONS,
    icon: <IconWithToolTip icon="automations" />,
    title: 'Automations',
  },
  {
    link: routes.SETUP,
    icon: <IconWithToolTip icon="setup" />,
    title: 'Setup',
  },
  {
    link: routes.LOGOUT,
    icon: <ExitToApp />,
    title: 'Logout',
  },
] satisfies HeaderMenuItem[];

export default function Sidebar({
  additionalItems,
}: {
  additionalItems?: HeaderMenuItem[];
}) {
  const location = useLocation();
  const menuItems = useMemo(
    () => [...(additionalItems ?? []), ...sideBarItems],
    [additionalItems]
  );
  return (
    <Box>
      <Typography variant="h6">Profile</Typography>
      <List>
        {menuItems.map((item) => {
          const { title, icon } = item;
          if ('link' in item) {
            return (
              <Link key={title} to={item.link}>
                <SideListItem
                  title={title}
                  icon={icon}
                  selected={location.pathname === item.link}
                />
              </Link>
            );
          }
          return (
            <SideListItem
              key={title}
              title={title}
              icon={icon}
              onClick={item.handleItemClick}
            />
          );
        })}
      </List>
    </Box>
  );
}
