import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  QueryClient as TrpcQueryClient,
  QueryClientProvider as TrpcQueryClientProvider,
} from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import 'assets/css/fontFacing.css';

import App from './App';
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory';
import { theme } from 'theme';

import { GlobalDialogProvider } from 'components/notification/GlobalDialog';
import {
  SnackbarNotificationProvider,
  useSnackbar,
} from 'components/notification/SnackbarNotification';
import SendbirdProvider from 'components/views/Messaging/SenbirdProvider';
import { store } from 'state_management';
import locale from 'components/locale';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { trpc, useTrpcClient } from 'util/trpc';
import { MissingTranslationError } from 'react-intl';
import { ServerError } from 'src/trpc/trpc';
import config from './config/config';
import MetaTags from './components/common/MetaTags';
import { useServiceWorkerWithUpdatePrompt } from './hooks/useServiceWorker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { PwaContextProvider } from 'context/PwaContext';
import { TRPCClientError } from '@trpc/client';
import { startCase } from 'lodash';

/**
 * @deprecated - moving to tRPC
 */
const restQueryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function AuthenticatedQueryProviders() {
  const { sendMessage } = useSnackbar();
  useServiceWorkerWithUpdatePrompt();

  const [trpcQueryClient] = useState(
    () =>
      new TrpcQueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60e3,
            refetchOnWindowFocus: config.trpcRefetchOnWindowFocus === 'true',
            onError(error) {
              const e = error as ServerError;
              const showUUID =
                e.shape?.severity === 'error' ? e.shape?.uuid : undefined;

              sendMessage({
                message: `Something went wrong, reason: ${
                  e.shape?.message ?? 'Unknown'
                }`,
                uuid: showUUID,
                severity: e.shape?.severity || 'warning',
              });
            },
          },
          mutations: {
            onError(error) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const e = error as TRPCClientError<any>;
              const showUUID =
                e.shape?.severity === 'error' ? e.shape?.uuid : undefined;

              if (
                e.shape?.message?.includes('Sale has ended') ||
                e.shape?.message?.includes('This sale has already ended.')
              ) {
                sendMessage({
                  message: 'Unable to place bid or purchase. Sale has ended.',
                  uuid: showUUID,
                  severity: e.shape?.severity || 'warning',
                });
                return;
              }

              const unknownErrorMessage =
                "Something went wrong, we're looking into it.";

              if (e.data && 'details' in e.data) {
                const info = Object.entries(e.data.details)
                  .map(([key, value]) => `${startCase(key)}:${value}`)
                  .join(', ');

                sendMessage({
                  message: `${e.shape?.message ?? unknownErrorMessage} ${info}`,
                  uuid: showUUID,
                  severity: e.shape?.severity || 'warning',
                });
                return;
              }

              sendMessage({
                message: `${e.shape?.message ?? unknownErrorMessage}`,
                uuid: showUUID,
                severity: e.shape?.severity || 'warning',
              });
            },
          },
        },
      })
  );
  const trpcClient = useTrpcClient();

  return (
    <trpc.Provider client={trpcClient} queryClient={trpcQueryClient}>
      <QueryClientProvider client={restQueryClient}>
        <TrpcQueryClientProvider client={trpcQueryClient}>
          <SendbirdProvider>
            <PwaContextProvider>
              <App />
            </PwaContextProvider>
          </SendbirdProvider>
        </TrpcQueryClientProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

function Root() {
  return (
    <HelmetProvider>
      <MetaTags
        title="RoundTable Trading"
        description="RoundTable Trading Platform and Social Groups"
      />
      <Provider store={store}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <IntlProvider
                  messages={locale.messages[locale.lang]}
                  locale={locale.lang}
                  defaultLocale="en"
                  onError={(error) => {
                    if (error instanceof MissingTranslationError) {
                      /* noop*/
                      return;
                    }
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <GlobalDialogProvider>
                      <SnackbarNotificationProvider>
                        <AuthenticatedQueryProviders />
                      </SnackbarNotificationProvider>
                    </GlobalDialogProvider>
                  </LocalizationProvider>
                </IntlProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
