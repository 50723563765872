import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from './useIsMounted';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is a generic
type DebounceTarget = (...args: any[]) => any;

export default function useFuncDebounce<Func extends DebounceTarget>(
  func: Func,
  delay: number,
  options = {
    leading: true,
    trailing: false,
  }
) {
  const inputsRef = useRef<{ func: Func; delay: number }>({ func, delay });
  const isMounted = useIsMounted();

  useEffect(() => {
    inputsRef.current = { func, delay };
  }, [func, delay]);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- react doesnt understand debounce
  return useCallback(
    debounce(
      (...args: Parameters<Func>) => {
        if (inputsRef.current.delay === delay && isMounted()) {
          return inputsRef.current.func(...args);
        }
      },
      delay,
      options
    ),
    [delay, options, inputsRef.current.delay, inputsRef.current.func, isMounted]
  );
}
