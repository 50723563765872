import React from 'react';
import { Popover } from '@mui/material';
import NotificationsList from './NotificationsList';

type PopoverNotificationsProps = {
  anchor: HTMLButtonElement | null;
  handleClose: () => void;
};

export default function PopoverNotifications({
  anchor,
  handleClose,
}: PopoverNotificationsProps) {
  const anchorBoolean = Boolean(anchor);

  return (
    <Popover
      open={anchorBoolean}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // TransitionProps={{ onExited: onNotificationsRead }}
    >
      <NotificationsList
        shouldFetchNotifications={anchorBoolean}
        onSeeAllClick={handleClose}
      />
    </Popover>
  );
}
