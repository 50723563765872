import { useSubscribeToPusherChannels } from '../notifications/pusherChannels';
import { useBindToRequestsEvent } from '../notifications/useBindToChannelEvent';
import { addDatadogError } from 'util/datadog';

export function useUpdateGroupChannelSubscriptions({
  enabled,
}: { enabled?: boolean } = {}) {
  const reSubscribe = useSubscribeToPusherChannels();

  useBindToRequestsEvent(
    'groupRequest:updated',
    async () => {
      await reSubscribe().catch((error) => {
        addDatadogError(error, {
          message: 'Failed to resubscribe to pusher channels',
        });
      });
    },
    { enabled }
  );
}
