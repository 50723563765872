import React, { SyntheticEvent } from 'react';
import { Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import colors from '../../theme/colors';

type NotificationButtonProps = {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  size: 'small' | 'medium' | 'large';
  badgeContent: number;
  showBadge: boolean;
};

export default function NotificationButton({
  onClick,
  size,
  badgeContent,
  showBadge,
}: NotificationButtonProps) {
  return (
    <IconButton onClick={onClick} size={size}>
      <Badge badgeContent={badgeContent} color="primary" invisible={showBadge}>
        <NotificationsIcon htmlColor={colors.white} />
      </Badge>
    </IconButton>
  );
}
