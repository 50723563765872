import React from 'react';
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MiniGroupList from 'components/utilities/groups/MiniGroupList';
import { HandleQueryStates } from 'components/utilities/HandleQueryStates';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import routes from 'constants/routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { trpc } from '../../util/trpc';
import { Theme } from '@mui/material/styles';
import { IconWithToolTip } from '../common/icons';
import { InfiniteScroll } from 'components/common/InfiniteScroll';
import { useGroupsForUser } from '../../services/groups/useGroupsForUser';

const useStyles = makeStyles<Theme, { selected?: boolean }>((theme) => ({
  avatar: ({ selected }) => {
    const color = selected
      ? theme.palette.primary.main
      : theme.palette.grey[300];

    return {
      color: theme.palette.getContrastText(color),
      backgroundColor: color,
    };
  },
  listItem: {
    borderRadius: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
}));

const LINKS = [
  {
    name: 'NGC',
    link: 'https://www.ngccoin.com/',
  },
  { name: 'Heritage Auctions', link: 'https://www.ha.com' },
  {
    name: 'Stack Bowers',
    link: 'https://www.stacksbowers.com/Pages/Home.aspx',
  },
  { name: 'Track and Price', link: 'http://trackandprice.com' },
  { name: 'Numis Media', link: 'http://numismedia.com' },
  { name: 'Kitco', link: 'https://www.kitco.com/market/' },
  { name: 'PCGS Coin Facts', link: 'https://www.pcgs.com/coinfacts' },
  { name: 'Grey Sheet', link: 'https://www.greysheet.com' },
  {
    name: 'Great Collections Auction Archive',
    link: 'https://www.greatcollections.com/Auction-Archive',
  },
  { name: 'NBN Census', link: 'https://nbncensus.com' },
  { name: 'Coin World', link: 'https://www.coinworld.com' },
  { name: 'Ebay', link: 'https://www.ebay.com' },
] as const;

const LinkList = () => {
  const classes = useStyles({});

  return (
    <Accordion defaultExpanded disableGutters sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        className={classes.listItem}
        expandIcon={<DoubleArrowIcon color="primary" />}
      >
        <ListItem sx={{ flexDirection: 'column', padding: 0 }}>
          <Box display="flex" justifyContent="flex-start" width="100%">
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LinkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Links"
              primaryTypographyProps={{ color: 'primary' }}
              secondaryTypographyProps={{ color: 'textSecondary' }}
            />
          </Box>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <List>
          {LINKS.map(({ name, link }) => (
            <ListItem key={name}>
              <Link href={link} target="_blank" sx={{ textDecoration: 'none' }}>
                {name}
              </Link>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export const SideListItem = React.memo(function SideListItem({
  title,
  subTitle,
  icon,
  buttonIcon,
  onClick,
  selected,
}: {
  title: string;
  subTitle?: string;
  icon: React.ReactElement;
  buttonIcon?: React.ReactElement;
  selected?: boolean;
  onClick?(): void;
}) {
  const classes = useStyles({ selected });
  return (
    <ListItem
      selected={selected}
      className={classes.listItem}
      onClick={onClick}
      sx={{ marginTop: 1 }}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={subTitle}
        primaryTypographyProps={{ color: 'primary' }}
        secondaryTypographyProps={{ color: 'textSecondary' }}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" color="primary" onClick={onClick} size="large">
          {buttonIcon ?? <DoubleArrowIcon />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
});

function useActiveRouteIdOnMatch({ route }: { route: string }) {
  const location = useLocation();
  const params = useParams<{ id?: string }>();

  return params.id != null &&
    location.pathname === route.replace(':id', params.id)
    ? params.id ?? true
    : undefined;
}

function GroupList() {
  const activeGroupId = useActiveRouteIdOnMatch({ route: routes.GROUP_FEED });
  const myGroupsResponse = useGroupsForUser({ status: 'active' });

  return (
    <HandleQueryStates response={myGroupsResponse}>
      {({ response }) => {
        const groups = response.data.pages.flatMap((p) => p.data) ?? [];
        return (
          <InfiniteScroll
            dataLength={groups.length || 0}
            next={response.fetchNextPage}
            hasMore={response.hasNextPage ?? false}
            isLoading={myGroupsResponse.isLoading}
          >
            <MiniGroupList activeGroupId={activeGroupId} groups={groups} />
          </InfiniteScroll>
        );
      }}
    </HandleQueryStates>
  );
}

function SideBarList() {
  const history = useHistory();
  const activeUserId = useActiveRouteIdOnMatch({ route: routes.MEMBER_TABLE });
  const activeCompanyId = useActiveRouteIdOnMatch({
    route: routes.COMPANY_TABLE,
  });
  // const isSalesViewActive = useActiveRouteIdOnMatch({
  //   route: routes.MEMBERS_SALES,
  // });
  const isRulesViewActive = useActiveRouteIdOnMatch({
    route: routes.AUTOMATIONS,
  });

  const userResponse = trpc.users.getOrThrow.useQuery({});

  return (
    <HandleQueryStates response={userResponse}>
      {({ response }) => {
        const user = response.data;
        return (
          <List>
            <SideListItem
              title={user.name}
              subTitle="Go to Table"
              selected={activeUserId === user.id.toString()}
              icon={<IconWithToolTip icon="table" />}
              onClick={() =>
                history.push(routes.MEMBER_TABLE.replace(':id', `${user.id}`))
              }
            />
            <SideListItem
              title={user.company?.companyName ?? 'No Company'}
              subTitle="Go to Table"
              selected={activeCompanyId === user.company?.id?.toString()}
              icon={<IconWithToolTip icon="company" />}
              onClick={() =>
                history.push(
                  routes.COMPANY_TABLE.replace(
                    ':id',
                    `${user.company?.id ?? ''}`
                  )
                )
              }
            />
            {/*<SideListItem*/}
            {/*  title="Sales"*/}
            {/*  subTitle="View Sales"*/}
            {/*  selected={isSalesViewActive != null}*/}
            {/*  icon={<BusinessIcon />}*/}
            {/*  onClick={() =>*/}
            {/*    history.push(routes.MEMBERS_SALES.replace(':id', `${user.id}`))*/}
            {/*  }*/}
            {/*/>*/}
            <SideListItem
              title="Automations"
              subTitle="View Post Automations"
              selected={isRulesViewActive != null}
              icon={<IconWithToolTip icon="automations" />}
              onClick={() => history.push(routes.AUTOMATIONS)}
            />
            <LinkList />
          </List>
        );
      }}
    </HandleQueryStates>
  );
}

export default function Sidebar() {
  return (
    <Box>
      <Typography variant="h6">Active Groups</Typography>
      <GroupList />
      <hr />
      <Typography variant="h6">Links</Typography>
      <SideBarList />
    </Box>
  );
}
