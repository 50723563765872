/* eslint-disable @typescript-eslint/no-explicit-any -- this file is loaded with generics where any is okay */
import React from 'react';
import {
  InfiniteData,
  // UseInfiniteQueryResult,
  // InfiniteQueryObserverSuccessResult,
  QueryObserverSuccessResult,
} from '@tanstack/react-query';
import {
  UseInfiniteQueryResult,
  InfiniteQueryObserverSuccessResult,
  QueryObserverSuccessResult as DeprecatedQueryObserverSuccessResult,
} from '@tanstack/react-query';
import Loader from 'components/utilities/Loader';
import { Alert, AlertTitle, Skeleton } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import {
  UseTRPCQueryResult,
  UseTRPCQuerySuccessResult,
  UseTRPCInfiniteQueryResult,
  UseTRPCInfiniteQuerySuccessResult,
  DefinedUseTRPCQueryResult,
} from '@trpc/react-query/shared';

type BaseProps =
  | {
      loaderAs?: 'spinner';
    }
  | ({
      loaderAs?: 'skeleton';
    } & (
      | {
          // provide a custom loading skeleton
          skeleton?: React.ReactNode;
        }
      | {
          // override the existing skeletons props
          SkeletonProps?: React.ComponentProps<typeof Skeleton>;
        }
    ));

export type InferData<I> = I extends DefinedUseTRPCQueryResult<infer Data, any>
  ? Data
  : I extends UseTRPCQueryResult<infer Data, any>
  ? Data
  : I extends UseTRPCInfiniteQueryResult<infer Data, any>
  ? Data
  : I extends UseInfiniteQueryResult<infer Data>
  ? Data
  : I extends UseQueryResult<infer Data>
  ? Data
  : never;

type InferError<I> = I extends DefinedUseTRPCQueryResult<any, infer Error>
  ? Error
  : I extends UseTRPCQueryResult<any, infer Error>
  ? Error
  : I extends UseTRPCInfiniteQueryResult<any, infer Error>
  ? Error
  : I extends UseInfiniteQueryResult<any, infer Error>
  ? Error
  : I extends UseQueryResult<any, infer Error>
  ? Error
  : never;

type InferInfiniteData<I> = I extends InfiniteData<infer Data> ? Data : never;

export function HandleQueryStates<
  R extends UseTRPCInfiniteQueryResult<any, any>,
  InfiniteData extends InferData<R>,
  Data extends InferInfiniteData<InfiniteData>,
  Error extends InferError<R>,
  Success extends UseTRPCInfiniteQuerySuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement;

export function HandleQueryStates<
  R extends UseTRPCQueryResult<any, any>,
  Data extends InferData<R>,
  Error extends InferError<R>,
  Success extends UseTRPCQuerySuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement;
export function HandleQueryStates<
  R extends DefinedUseTRPCQueryResult<any, any>,
  Data extends InferData<R>,
  Error extends InferError<R>,
  Success extends QueryObserverSuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement;

export function HandleQueryStates<
  R extends UseInfiniteQueryResult<any, any>,
  Data extends InferData<R>,
  Error extends InferError<R>,
  Success extends InfiniteQueryObserverSuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement;

export function HandleQueryStates<
  R extends UseQueryResult<any, any>,
  Data extends InferData<R>,
  Error extends InferError<R>,
  Success extends DeprecatedQueryObserverSuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement;

export function HandleQueryStates<
  R extends
    | UseInfiniteQueryResult<any, any>
    | UseQueryResult<any, any>
    | DefinedUseTRPCQueryResult<any, any>
    | UseTRPCInfiniteQueryResult<any, any>,
  Data extends InferData<R>,
  Error extends InferError<R>,
  Success extends
    | QueryObserverSuccessResult<Data, Error>
    | InfiniteQueryObserverSuccessResult<Data, Error>
    | UseTRPCQuerySuccessResult<Data, Error>
    | UseTRPCInfiniteQuerySuccessResult<Data, Error>,
  Children extends ({
    response,
  }: {
    response: Success;
  }) => React.ReactElement | null
>({
  response,
  children,
  loaderAs = 'skeleton',
  ...props
}: {
  response: R;
  children: Children;
} & BaseProps): React.ReactElement {
  if (response.isLoading) {
    if (loaderAs === 'skeleton') {
      if ('skeleton' in props && props.skeleton != null) {
        return <>{props.skeleton}</>;
      }
      if ('SkeletonProps' in props) {
        return (
          <Skeleton
            animation="wave"
            height="100%"
            width="100%"
            {...props.SkeletonProps}
          />
        );
      }
      return <Skeleton animation="wave" height="100%" width="100%" />;
    }
    return <Loader />;
  }
  if (response.isError || !response.isSuccess) {
    return (
      <Alert severity="error">
        <AlertTitle>Unexpected Error</AlertTitle>
        {response?.error?.message ?? 'Something went wrong'}
      </Alert>
    );
  }

  // @ts-expect-error - R extends Success by narrowing
  return <>{children({ response })}</>;
}
