import { useAuth0 } from '@auth0/auth0-react';

export default function useUserRoles(): string[] {
  const { user } = useAuth0();
  if (process.env.REACT_APP_AUTH0_AUDIENCE == null) {
    throw new Error(`No REACT_APP_AUTH0_AUDIENCE provided`);
  }

  if (user == null) {
    return [];
  }

  const roles: undefined | string[] =
    user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`];

  if (roles == null) {
    return [];
  }

  return roles;
}

export function useUserRoleIncludes(role: 'Admin'): boolean {
  const roles = useUserRoles();

  return roles?.includes(role);
}
