import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { withRum } from './RumRouteComponentWrapper';

// component will not render lazy loaded components correctly because withRum misidentifies them
type RumRouteProps = RouteProps & { component?: never };

export const RumRoute = ({
  children,
  component,
  render,
  ...otherProps
}: RumRouteProps) => {
  const RumComponent = useMemo(() => {
    // this is react-router priority
    return withRum(children ?? component ?? render);
  }, [children, component, render]);

  return <Route {...otherProps} component={RumComponent} />;
};
