import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DialogProps, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles<
  Theme,
  { width?: string | number | null; height?: number | string }
>((theme: Theme) =>
  createStyles({
    content: ({ width, height }) => ({
      backgroundColor: theme.palette.background.paper,
      ...(width && { width }),
      height,
      padding: theme.spacing(2, 2, 3),
    }),
    arrow: {
      cursor: 'pointer',
    },
  })
);

export type ModalProps = {
  open: boolean;
  title: React.ReactNode;
  onCloseModal: () => void;
  children: React.ReactElement | React.ReactNode;
  aria: {
    'aria-labelledby': string;
    'aria-describedby': string;
  };
  contentWidth?: string | number;
} & Partial<Omit<DialogProps, 'title'>>;

function useContentWidth({
  isSmallScreen,
  contentWidth,
  fullWidth,
}: {
  isSmallScreen: boolean;
  contentWidth?: string | number;
  fullWidth?: boolean;
}): string | number | null {
  if (contentWidth) {
    return contentWidth;
  }

  if (isSmallScreen) {
    // dialogs on small screens will fill the screen
    return null;
  }

  if (fullWidth) {
    // if caller wants a full width modal, we want the content to fill that modal
    return null;
  }
  // the content width will dictate the modal size, so lets make it 1/2 the screen be default
  return '50rem';
}

export default function CommonModal({
  open,
  title,
  onCloseModal,
  children,
  aria,
  contentWidth: contentWidthOverride,
  ...props
}: ModalProps) {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const contentWidth = useContentWidth({
    contentWidth: contentWidthOverride,
    isSmallScreen,
    fullWidth: props.fullWidth,
  });

  const classes = useStyles({
    width: contentWidth,
  });

  return (
    <Dialog
      {...aria}
      scroll="body"
      {...props}
      fullScreen={isSmallScreen}
      maxWidth={isSmallScreen ? 'sm' : 'lg'}
      open={open}
      onClose={onCloseModal}
    >
      <DialogContent
        classes={{ root: classes.content }}
        id="modal-scroll-target"
      >
        <Box mb={1} display="flex" columnGap={1} alignItems="center">
          <ArrowBack onClick={() => onCloseModal()} className={classes.arrow} />
          {typeof title === 'string' ? (
            <Typography variant="h6">{title}</Typography>
          ) : (
            title
          )}
        </Box>
        <Box mb={2}>
          <hr />
        </Box>

        {children}
      </DialogContent>
    </Dialog>
  );
}
