import GradientLogo from 'assets/images/logos/logo-gradient.png';
import WhiteLogo from 'assets/images/logos/logo-w.png';
import blueLogoText from 'assets/images/logos/round-table-trading-logo.png';
import DarkBlueLogo from 'assets/images/logos/dark-blue-logo.png';
import Favicon from 'assets/images/logos/favicon.png';
import FaviconBlack from 'assets/images/logos/black-favicon.png';
import BlackLogo from 'assets/images/logos/black-logo.png';
import WhiteLogoText from 'assets/images/logos/white-logo-text.png';
import FaviconWhite from 'assets/images/logos/white-favicon.png';

// All Logos are in an objects with their name as key and value:
const Logos = {
  GradientLogo,
  WhiteLogo,
  blueLogoText,
  DarkBlueLogo,
  Favicon,
  FaviconBlack,
  BlackLogo,
  WhiteLogoText,
  FaviconWhite,
};

/**
 * @deprecated - move to the  {@Link CommonLogos} component
 */
export default Logos;
