import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect, useRef } from 'react';
import config from 'config/config';

function isDatadogDisabled() {
  return config.env === 'development' || config.env === 'test';
}

export function useInitializedDatadog() {
  const isInitialized = useRef(false);

  React.useEffect(() => {
    if (isDatadogDisabled()) {
      return;
    }

    if (
      config.datadog.applicationId == null ||
      config.datadog.clientToken == null
    ) {
      console.warn(
        'Datadog init variables (applicationId or clientToken) are empty'
      );
      return;
    }
    if (isInitialized.current) {
      return;
    }

    datadogRum.init({
      applicationId: config.datadog.applicationId,
      clientToken: config.datadog.clientToken,
      site: 'datadoghq.com',
      service: `rtt-webapp-${config.appEnv}`,
      env: config.appEnv,
      version: config.version,

      trackUserInteractions: true,
      trackFrustrations: true,
      trackViewsManually: true, // track views via RumRoute
      defaultPrivacyLevel: 'mask-user-input',
      sessionSampleRate: 100, // todo: reduce this in production to save on costs
      sessionReplaySampleRate: 100, // todo: reduce this in production to save on costs
    });
    isInitialized.current = true;
  });
}

// extend options as needed
type Datadog = Pick<
  typeof datadogRum,
  | 'startView'
  | 'addError'
  | 'addAction'
  | 'setUser'
  | 'startSessionReplayRecording'
  | 'stopSessionReplayRecording'
>;

type DatadogAction = keyof Datadog;

export function useDatadogAction<
  Action extends DatadogAction,
  Params extends Parameters<Datadog[Action]> = Parameters<Datadog[Action]>
>(action: Action) {
  return (...params: Params) => {
    if (isDatadogDisabled()) {
      console.log(`Datadog rum:`, { action, params });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error - some functions take multiple args..
    datadogRum[action]?.(...params);
  };
}

export function useSessionRecording() {
  const startSessionRecording = useDatadogAction('startSessionReplayRecording');
  const stopSessionReplayRecording = useDatadogAction(
    'stopSessionReplayRecording'
  );

  useEffect(() => {
    startSessionRecording();
  }, [startSessionRecording]);

  return {
    stopRecording: stopSessionReplayRecording,
  };
}

export function useDataDogLogout() {
  return {
    removeUserAndEndSession() {
      if (isDatadogDisabled()) {
        console.log(`Datadog logout`);
        return;
      }
      datadogRum.removeUser();
      datadogRum.stopSessionReplayRecording();
    },
  };
}

export function addDatadogError(
  error: unknown,
  context?: Record<string, unknown> | React.ErrorInfo
) {
  if (isDatadogDisabled()) {
    console.log(`Datadog rum(addError):`, JSON.stringify({ error, context }));
    return;
  }
  datadogRum.addError(error, context);
}

type PushNotification<
  A extends 'Enabled' | 'Set User' | 'Clear User' | 'Initialized' =
    | 'Enabled'
    | 'Set User'
    | 'Clear User'
    | 'Initialized'
> = `Push Notifications ${A}`;

type Action = PushNotification;

export function addDatadogAction(
  action: Action,
  context?: Record<string, unknown> | React.ErrorInfo
) {
  if (isDatadogDisabled()) {
    console.log(`Datadog rum(addAction): ${action}`, JSON.stringify(context));
    return;
  }
  datadogRum.addAction(action, context);
}
