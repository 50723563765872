import { useIntl } from 'react-intl';
import enUS from '../lang/en-US.json';

export type TranslationKey = keyof typeof enUS;

export type Formatter = (key: TranslationKey | string) => string;

interface IUseLang {
  f: Formatter;
  translation: Formatter;
}

/**
 * @deprecated - we don't need to support multiple languages at this time
 */
const useLang = (): IUseLang => {
  const { formatMessage } = useIntl();

  function translation(key: TranslationKey | string): string {
    return formatMessage({ id: key });
  }

  return {
    f: translation,
    translation,
  };
};

export default useLang;
