import React from 'react';
import { BadgeProps, Tooltip, TooltipProps } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import LocalAtm from '@mui/icons-material/LocalAtm';
import { startCase } from 'lodash';
import {
  AccountCircle,
  ArrowCircleRight,
  ArrowCircleUp,
  AttachMoney,
  Check,
  CheckCircle,
  CloseOutlined,
  Delete,
  DeleteForever,
  Edit,
  Groups,
  HelpOutlined,
  HorizontalSplitOutlined,
  ImageOutlined,
  Info,
  LocalOffer,
  MessageOutlined,
  MonetizationOnOutlined,
  PlayArrow,
  RadioButtonUnchecked,
  Reply,
  Search,
  Segment,
  Settings,
  SmartToyOutlined,
  Store,
  TableRestaurant,
  ThumbUp,
  ChatBubbleOutline,
  FilterList,
  Category,
  SensorOccupied,
  Dashboard,
  Create,
  MoreHoriz,
  Tune,
  Share,
} from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import MemoAvatar from './images/MemoAvatar';
import LinkIcon from '@mui/icons-material/Link';

type IconProps = React.ComponentProps<typeof GavelIcon>;

export type IconName = keyof typeof icons;

export type IconWithBadge = IconProps & {
  icon: IconName;
  badgeProps?: BadgeProps;
} & AsAvatar;

export type IconWithTooltipProps = IconWithBadge & {
  icon: IconName;
  toolTipProps?: Omit<TooltipProps, 'title' | 'children'> & { title?: string };
  badgeProps?: BadgeProps;
};

const icons = {
  discussionPost: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <MessageOutlined {...iconProps} />
  ),
  salePost: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <AttachMoney {...iconProps} />
  ),
  auction: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <GavelIcon {...iconProps} />
  ),
  fixedPrice: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <MonetizationOn {...iconProps} />
  ),
  privateOffer: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <LocalAtm {...iconProps} />
  ),
  checkFilled: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <CheckCircle {...iconProps} />
  ),
  checkSimple: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Check {...iconProps} />
  ),
  checkEmpty: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <RadioButtonUnchecked {...iconProps} />
  ),
  coinSale: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <MonetizationOnOutlined {...iconProps} />
  ),
  bullionSale: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <HorizontalSplitOutlined {...iconProps} />
  ),
  miscSale: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <HelpOutlined {...iconProps} />
  ),
  media: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <ImageOutlined {...iconProps} />
  ),
  explicitTag: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <LocalOffer {...iconProps} />
  ),
  close: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <CloseOutlined {...iconProps} />
  ),
  accept: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <CheckCircle {...iconProps} />
  ),
  info: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Info {...iconProps} />
  ),
  automations: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <SmartToyOutlined {...iconProps} />
  ),
  table: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <TableRestaurant {...iconProps} />
  ),
  company: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Store {...iconProps} />
  ),
  profile: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <AccountCircle {...iconProps} />
  ),
  groups: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Groups {...iconProps} />
  ),
  reply: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Reply {...iconProps} />
  ),
  settings: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Settings {...iconProps} />
  ),
  setup: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <PlayArrow {...iconProps} />
  ),
  search: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Search {...iconProps} />
  ),
  arrowRight: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <ArrowCircleRight {...iconProps} />
  ),
  arrowUp: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <ArrowCircleUp {...iconProps} />
  ),
  like: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <ThumbUp {...iconProps} />
  ),
  edit: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Edit {...iconProps} />
  ),
  thread: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Segment {...iconProps} />
  ),
  remove: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Delete {...iconProps} />
  ),
  adminRemove: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <DeleteForever {...iconProps} />
  ),
  chatBubbleOutline: (
    iconProps: React.ComponentProps<typeof ChatBubbleOutline>
  ) => <ChatBubbleOutline {...iconProps} />,
  filter: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <FilterList {...iconProps} />
  ),
  link: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <LinkIcon {...iconProps} />
  ),
  category: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Category {...iconProps} />
  ),
  myPosts: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <SensorOccupied {...iconProps} />
  ),
  homeFeed: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Dashboard {...iconProps} />
  ),
  create: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Create {...iconProps} />
  ),
  three_dots: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <MoreHoriz {...iconProps} />
  ),
  sort: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Tune {...iconProps} />
  ),
  share: (iconProps: React.ComponentProps<typeof GavelIcon>) => (
    <Share {...iconProps} />
  ),
};

type AsAvatar =
  | {
      asAvatar?: true;
      avatarProps?: React.ComponentProps<typeof MemoAvatar>;
    }
  | {
      asAvatar?: false | never | undefined;
      avatarProps?: never;
    };

export function IconOnly({
  icon,
  ...props
}: { icon: IconName } & AsAvatar & IconProps) {
  const Icon = icons[icon];
  if ('asAvatar' in props) {
    const { asAvatar: _ignored, avatarProps, ...rest } = props;
    return (
      <MemoAvatar {...avatarProps}>
        <Icon {...rest} />
      </MemoAvatar>
    );
  }
  return <Icon {...props} />;
}

export function IconWithToolTip({
  icon,
  toolTipProps,
  badgeProps,
  ...iconProps
}: IconWithTooltipProps) {
  return (
    <Tooltip
      arrow
      title={startCase(icon)}
      placement="top"
      enterTouchDelay={0}
      leaveTouchDelay={8000}
      {...toolTipProps}
    >
      <span>
        <IconWithBadge icon={icon} badgeProps={badgeProps} {...iconProps} />
      </span>
    </Tooltip>
  );
}

export function IconWithBadge({
  icon,
  badgeProps,
  ...iconProps
}: IconWithBadge) {
  if (badgeProps) {
    return (
      <Badge {...badgeProps}>
        <IconOnly icon={icon} {...iconProps} />
      </Badge>
    );
  }
  return <IconOnly icon={icon} {...iconProps} />;
}
