import React from 'react';
import { Box } from '@mui/material';
import Logos from '../../resources/enums/LogoEnums';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../theme/colors';
import MemoImage from '../common/images/MemoImage';

export const useStyles = makeStyles<Theme, { hasAction?: boolean }>({
  imageBox: {
    textAlign: 'center',
    cursor: ({ hasAction }) => (hasAction ? 'pointer' : undefined),
  },

  whiteLogoBg: {
    backgroundColor: colors.primary.main,
    padding: '30px 0',
  },
});

export type LogosProps = {
  /**
   * Provide the type of logo:
   */
  type?:
    | 'GradientLogo'
    | 'blueLogoText'
    | 'Favicon'
    | 'DarkBlueLogo'
    | 'BlackLogo'
    | 'FaviconBlack'
    | 'WhiteLogo'
    | 'WhiteLogoText'
    | 'FaviconWhite';
  /**
   * Provide the alt label of the logo:
   */
  altLabel: string;
  storyBookDemo?: boolean;
} & (
  | {
      href: string;
    }
  | { onClick?(): void }
);

/**
 * Primary UI Logos component.
 */
export function CommonLogos({
  type = 'GradientLogo',
  altLabel,
  storyBookDemo = false,
  ...props
}: LogosProps) {
  const styleClasses = useStyles({
    hasAction: 'onClick' in props || 'href' in props,
  });

  // We have done this so that the white logos are visible. This is simply for demonstration:
  const whiteLogos = ['WhiteLogo', 'WhiteLogoText', 'FaviconWhite'];

  const image = <MemoImage src={Logos[type]} alt={altLabel} />;

  return (
    <>
      <Box
        onClick={'onClick' in props ? props.onClick : undefined}
        className={`${styleClasses.imageBox} ${
          whiteLogos.includes(type) && storyBookDemo && styleClasses.whiteLogoBg
        }`}
      >
        {'href' in props && props.href ? (
          <a href={props.href}>{image}</a>
        ) : (
          image
        )}
      </Box>
    </>
  );
}
