import { useSendBirdGroupChannelQuery } from './useSendBirdGroupChannelQuery';
import { useActiveUser } from '../user/useActiveUser';

export function useGetTotalUnreadChannelCount() {
  const { data } = useActiveUser();
  return useSendBirdGroupChannelQuery({
    method: 'getTotalUnreadChannelCount',
    params: [],
    options: {
      /**
       * only enable if we have a user
       */
      enabled: data != null,
      /*
       * check every 2 minutes for updates
       */
      refetchInterval: 1000 * 60 * 2,
      /**
       * consider the data as fresh for 30 seconds
       */
      staleTime: 1000 * 30,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      /**
       * lets not blast sendbird
       */
      refetchIntervalInBackground: false,
    },
  });
}
