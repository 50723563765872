import { RouterOutput, trpc } from 'util/trpc';
import { useSelector } from 'react-redux';
import { RootState } from 'state_management/reducers';
import { useRoundtableAuth0 } from '../auth/hooks/useRoundtableAuth0';
import { useRoundtableAuth0UserAudienceAttribute } from '../auth/hooks/useRoundtableAuth0UserAudienceAttribute';
import { UserId } from 'src/services/userService/models/user.model';
import { useCallback } from 'react';

export type User = RouterOutput['users']['getOrThrow'];

/**
 * @deprecated - we need to get off redux
 */
export const useActiveUserFromState = () => {
  return useSelector((state: RootState) => state.authReducer.user);
};

export function useActiveUser(options?: { enabled?: boolean }) {
  const { isAuthenticated } = useRoundtableAuth0();
  return trpc.users.getOrThrow.useQuery(
    {},
    {
      staleTime: 60 * 1000 * 2,
      ...options,
      enabled: isAuthenticated && options?.enabled !== false,
    }
  );
}

export function useLazyActiveUser() {
  const trpcContext = trpc.useContext();
  return useCallback(async () => {
    return await trpcContext.client.users.getOrThrow.query({});
  }, [trpcContext.client.users.getOrThrow]);
}

/**
 * a blend of either the auth0 user attributes or the database user attributes
 */
type SimpleUser = {
  id: UserId | null;
  name: string | null;
  email: string | null;
  avatar: string | null;
};

/**
 * provides a blend of auth0 or database user attributes
 * user this if you don't need the full user object
 *
 */
export function useSimpleUser(): SimpleUser {
  const { user: auth0User } = useRoundtableAuth0();
  const userId = useRoundtableAuth0UserAudienceAttribute('userId');
  const { data: dbUser } = useActiveUser();

  return {
    id: dbUser?.id ?? userId ?? null,
    name: dbUser?.name ?? auth0User?.name ?? auth0User?.nickname ?? null,
    email: dbUser?.email ?? auth0User?.email ?? null,
    avatar: dbUser?.avatar ?? null,
  };
}
