import React, { useState } from 'react';
import ReactPlayer from 'react-player';

export type VideoPlayerProps = {
  url: string;
  aspectRatio?: string | undefined;
};

export default function VideoPlayer({ url, aspectRatio }: VideoPlayerProps) {
  const [ready, setReady] = useState(false);
  return (
    <ReactPlayer
      width="fit-content"
      height="100%"
      controls
      url={url}
      style={{
        aspectRatio: ready ? undefined : aspectRatio,
        minHeight: 0,
      }}
      onReady={() => setReady(true)}
    />
  );
}
