import React, { useEffect } from 'react';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { isSameDay, parseISO } from 'date-fns';
import { useDialog } from 'components/notification/GlobalDialog';
import { usePwaContext } from 'context/PwaContext';

export default function PwaInstallPrompt() {
  const { showDialog } = useDialog();
  const { isStandalone, canInstall, installPrompt } = usePwaContext();

  useEffect(() => {
    // Remove previous localStorage if exists //
    const oldPromptState = window.localStorage.getItem('pwaPromptDisabled');

    if (oldPromptState) {
      window.localStorage.removeItem('pwaPromptDisabled');
    }
  }, []);

  useEffect(() => {
    const { count, date } = getPromptState();
    const hasNotPromptedToday = !isSameDay(new Date(), parseISO(date));

    const promptInstall =
      !isStandalone && canInstall && count < 10 && hasNotPromptedToday;

    if (promptInstall) {
      showDialog({
        id: 'pwaInstallPrompt',
        title: 'Add app to home screen',
        content: PromptContent,
        cancelButtonLabel: 'No thanks',
        async onConfirm() {
          installPrompt?.();
        },
      });
      updatePromptCount({ newCount: count + 1 });
    }
  }, [canInstall, isStandalone, installPrompt, showDialog]);

  return null;
}

function PromptContent() {
  return (
    <Box display="grid" justifyItems="center" pb={4}>
      <Typography variant="h6">
        Install our app to your home screen to get:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <FastForwardIcon />
          </ListItemIcon>
          <ListItemText primary="Faster load times" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Push notifications" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <FullscreenIcon />
          </ListItemIcon>
          <ListItemText primary="Full-screen experience" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <DataUsageIcon />
          </ListItemIcon>
          <ListItemText primary="Less data usage" />
        </ListItem>
      </List>
      <Typography variant="body1">
        Enjoy a smoother, faster, and more efficient user experience.
      </Typography>
    </Box>
  );
}

const PWA_PROMPT = 'pwaPrompt';

function updatePromptCount({ newCount = 0 }: { newCount?: number }) {
  const promptData = {
    count: newCount,
    promptDate: new Date(),
  };

  window.localStorage.setItem(PWA_PROMPT, JSON.stringify(promptData));
}

function getPromptState() {
  const promptState = JSON.parse(
    window.localStorage.getItem(PWA_PROMPT) ?? '{}'
  );
  return {
    count: promptState.count ?? 0,
    date: promptState.promptDate,
  };
}
