import { useSnackbar } from 'components/notification/SnackbarNotification';
import { useCallback } from 'react';
import { ApiError } from 'util/api';
import ErrorCode from 'constants/errorCode';

export function useHandleError() {
  const { sendMessage } = useSnackbar();
  return useCallback(
    (error: ApiError) => {
      if ('details' in error && error.details) {
        sendMessage({
          message: `${error.message}: ${Array.from(
            Object.entries(error.details)
          )
            .map(([field, error]) => `${field}:${error}`)
            .join(' | ')}`,
          severity: 'error',
        });
        return;
      }
      if ('code' in error && error.code === ErrorCode.USER_HAS_COMPANY) {
        sendMessage({
          message:
            'You are already a part of a company and cannot move, at the moment.',
          severity: 'error',
        });
        return;
      }

      if ('code' in error && error.code === ErrorCode.USER_IS_COMPANY_OWNER) {
        sendMessage({
          message:
            'You currently own a company, and cannot move to another one, at the moment.',
          severity: 'error',
        });
        return;
      }
      sendMessage({ message: error.message, severity: 'error' });
    },
    [sendMessage]
  );
}
