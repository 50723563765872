import React from 'react';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { ChatBubbleOutline } from '@mui/icons-material';
import routes from 'constants/routes';
import colors from 'theme/colors';
import { useGetTotalUnreadChannelCount } from 'services/sendbird/useGetTotalUnreadChannels';
import { useActiveUser } from 'services/user/useActiveUser';
import IconButton from '@mui/material/IconButton';
import ErrorBoundary from '../utilities/ErrorBoundary';

function MessagingIconWithBadge() {
  const unreadChannelCountResponse = useGetTotalUnreadChannelCount();
  if (unreadChannelCountResponse.isSuccess) {
    return (
      <Badge badgeContent={unreadChannelCountResponse.data} color="secondary">
        <ChatBubbleOutline htmlColor={colors.white} />
      </Badge>
    );
  }
  return <ChatBubbleOutline htmlColor={colors.white} />;
}

export function MessagingIconButton() {
  const { data: user } = useActiveUser();
  if (user?.publicId == null) {
    // don't show messaging if user cannot message (also prevents checking for sendbird state before the provider is set)
    return null;
  }
  return (
    <ErrorBoundary>
      <IconButton
        edge="end"
        size="large"
        component={Link}
        to={routes.MESSAGING}
      >
        <MessagingIconWithBadge />
      </IconButton>
    </ErrorBoundary>
  );
}
