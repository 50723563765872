import { trpc } from '../../util/trpc';
import { GroupId } from 'src/services/groupService/models/group.model';

export function useGroup(
  groupId?: GroupId | null,
  options?: { enabled?: boolean }
) {
  return trpc.groups.getById.useQuery(
    { id: groupId! },
    { ...options, enabled: options?.enabled !== false && groupId != null }
  );
}
