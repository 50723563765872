import { trpc } from 'util/trpc';
import type { UserId } from 'src/services/userService/models/user.model';

export function useUser(userId?: UserId) {
  const user = trpc.users.get.useQuery(
    {
      userId,
    },
    {
      enabled: userId != null,
      /**
       * keep the data fresh for 5 minutes to avoid additional queries
       */
      staleTime: 1000 * 60 * 5,
    }
  );
  return user;
}
