import React, { CSSProperties, useCallback } from 'react';
import { styled } from '@mui/system';
import { ImageBar } from './ImageBar';
import ImageListItem from '@mui/material/ImageListItem';
import VideoPlayer from '../VideoPlayer';

const ImgBase = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  cursor: 'pointer',
  minHeight: 0, // https://css-tricks.com/preventing-a-grid-blowout/
});

function ignoredSrc(src?: string) {
  if (src?.startsWith('/static')) {
    return true;
  }

  if (src?.startsWith('blob:')) {
    return true;
  }
  return false;
}

function parseUrl(src?: string) {
  try {
    return new URL(src ?? '');
  } catch (error) {
    console.error('Failed to parse src into url', {
      src,
      error,
    });
  }
  return { pathname: src };
}

function areEqualShallow(
  a: Record<string, unknown>,
  b: Record<string, unknown>
) {
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (const key in b) {
    if (!(key in a)) {
      return false;
    }
  }
  return true;
}

export function srcIsEqual<
  O extends { src?: string },
  N extends { src?: string }
>(o: O, n: N): boolean {
  if (o.src != null && n.src != null && o.src === n.src) {
    return true;
  }

  if (o.src == null || n.src == null) {
    return false;
  }

  if (ignoredSrc(o.src) || ignoredSrc(n.src)) {
    return false;
  }

  try {
    const oldUrl = parseUrl(o.src);
    const newUrl = parseUrl(n.src);
    return oldUrl.pathname === newUrl.pathname;
  } catch (error) {
    console.error('Failed to parse src into url', {
      oSrc: o.src,
      nSrc: n.src,
      error,
    });
  }

  return areEqualShallow(o, n);
}

export type ImageProps = {
  onDelete?(): void;
  onClick?(): void;
  title?: string;
  mimeType?: string;
  aspectRatio?: number;
  objectFit?: CSSProperties['objectFit'];
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const MemoImage = React.memo(function Img({
  onDelete,
  title,
  mimeType,
  onClick,
  aspectRatio,
  style,
  objectFit,
  ...props
}: ImageProps) {
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  if (mimeType?.startsWith('video/') && props.src) {
    return (
      <>
        <ImageListItem
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <ImageBar title={title} onDelete={onDelete} />
        </ImageListItem>
        <VideoPlayer url={props.src} aspectRatio={aspectRatio?.toString()} />
      </>
    );
  }

  return (
    <>
      {(title || onDelete) && (
        <ImageListItem
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <ImageBar title={title} onDelete={onDelete} />
        </ImageListItem>
      )}
      <ImgBase
        alt="An Image possibly showing text"
        style={{
          ...style,
          aspectRatio: aspectRatio?.toString(),
          objectFit,
        }}
        {...props}
        onClick={handleClick}
      />
    </>
  );
},
srcIsEqual);

export default MemoImage;
