import React from 'react';

export type TemplateMatcher = {
  match: RegExp;
  render(): React.ReactNode | null;
};

function mapTemplateStringToNode(
  content: (string | React.ReactNode)[],
  templateMatchers: TemplateMatcher[]
): (string | React.ReactNode)[] {
  if (templateMatchers.length === 0) {
    return content;
  }

  const [mapper, ...remainingMatchers] = templateMatchers;
  const renderedParts = content.flatMap((part) => {
    if (typeof part !== 'string') {
      return part;
    }

    const parts = part.split(/\s/g);

    return parts.flatMap((p) => {
      if (p.trim() === '') {
        return [];
      }

      if (p.replace(mapper.match, 'exact').trim() === 'exact') {
        return [mapper.render()];
      }

      return [p];
    });
  });
  return mapTemplateStringToNode(renderedParts, remainingMatchers);
}

export function useTemplateContent(
  content: (string | React.ReactNode)[],
  templateMatchers: TemplateMatcher[]
): (string | React.ReactNode)[] {
  if (templateMatchers.length === 0) {
    return content;
  }

  return mapTemplateStringToNode(content, templateMatchers);
}
