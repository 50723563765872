/* eslint-disable @typescript-eslint/no-explicit-any -- this file is deprecated */
import { ApiResponse, TApiState } from 'resources/domain/entity/ApiResponse';
import ErrorCode from 'constants/errorCode';

const toString = Object.prototype.toString;

export const isObject = <T>(arg: T): boolean => {
  return toString.call(arg) === '[object Object]';
};

export type ApiError =
  | { message: string }
  | { code: ErrorCode | number; message: string }
  | {
      code: number;
      message: string;
      details: Record<string, string>;
      severity: 'warning' | 'error' | 'info';
    };

export const withError = <T extends TApiState>(arg: T): ApiResponse => {
  if (isObject(arg)) {
    return {
      data: null,
      error: {
        ...arg,
      } as ApiError, // todo: this is stupid
    };
  }

  return {
    data: null,
    error: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - this is stupid
      message: arg,
    },
  };
};

export const withData = <T extends Record<string, any> | null>(
  data: T
): ApiResponse => ({
  error: null,
  data,
});
