import React from 'react';
import z from 'zod';
import { IconButton, List, ListItem, Popover } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../theme/colors';
import { CommonButton } from './CommonButton';
import { match } from 'ts-pattern';
import Button from '@mui/material/Button';
import { IconName, IconOnly } from '../common/icons';

interface StyleProps {
  borderRequired?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    '& svg': {
      color: colors.grey.dark,
      fontSize: '30px',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '& span': {
      fontFamily: 'ProximaNovaRegular',
      fontWeight: 600,
    },
    border: (props: StyleProps) =>
      props.borderRequired ? `1px solid ${colors.grey.main}` : 'none',
  },
  listDropdownBg: {
    backgroundColor: colors.grey.matte,
    padding: '0 15px',
    minWidth: '160px',
    borderRadius: '10px',
    '& li': {
      borderBottom: `1px solid ${colors.grey.silver}`,
      padding: '12px 0',
      fontFamily: 'ProximaNovaSemiBold',
      color: colors.grey.main,
      cursor: 'pointer',
      '& svg': {
        marginRight: '5px',
      },
      '&:last-child': {
        border: 'none',
      },
    },
  },

  dropdownDisable: {
    opacity: '0.5',
    cursor: 'default',
  },
  buttonBgDot: {
    backgroundColor: colors.grey.light,
  },
}));

type IdType = string | number | z.BRAND<string>;

export type MenuItem<Id extends IdType = IdType> = {
  id?: Id;
  title: string;
  icon?: JSX.Element;
  disabled?: boolean;
  [K: string]: unknown;
};

export type DropDownPropsBase<MI extends MenuItem = MenuItem> = {
  /**
   * Provide the label for the DropDown:
   */
  label?: string;
  /**
   * Provide the items array of the ListItem that will be presented as options:
   */
  items: readonly MI[];
  /**
   * Provide the functionality to perform an onItemClick when a ListItem is clicked on:
   */
  onItemClick?: (item: MI) => void;
  /**
   * Provide the option to show or not show a border:
   */
  borderRequired?: boolean;
  /**
   * Provide if the DropDown needs to be enabled or disabled:
   */
  disabled?: boolean;
  /**
   * Provide if the DropDown should be an icon:
   */
  showIcon?: boolean;

  as?: '3dots' | 'arrow' | 'button';
  /**
   * Provide if the DropDown anchor position needs to be changed:
   */
  anchorOrigin?: {
    vertical: 'bottom' | 'center' | 'top';
    horizontal: 'left' | 'center' | 'right';
  };
  /**
   * Provide if the DropDown popup position needs to be changed
   */
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };

  dropDownIcon?: IconName;
};

export type DropDownProps<MI extends MenuItem = MenuItem> =
  | (DropDownPropsBase<MI> & {
      as: 'arrow' | 'button';
      background?: never;
    })
  | (DropDownPropsBase<MI> & {
      as: '3dots';
      /**
       * the original dev team did an absolute garbage job using the MUI API - so we have custom styling cruft like this
       */
      background?: 'grey';
    });

/**
 * Primary UI DropDown component for user interonItemClick.
 */
export function DropDown<MI extends MenuItem = MenuItem>({
  label,
  items,
  onItemClick,
  disabled = false,
  as,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  dropDownIcon = 'three_dots',
  background,
}: DropDownProps<MI>) {
  const styleClasses = useStyles({ borderRequired: false });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // Function that gets executed when an items has been clicked on:
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function that is executed when the dropdown is closed:
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const button = match(as)
    .with('3dots', () => (
      <IconButton
        edge="end"
        aria-label="dropdown"
        color="primary"
        className={background === 'grey' ? styleClasses.buttonBgDot : undefined}
        onClick={handleClick}
        size="large"
      >
        <IconOnly icon={dropDownIcon} />
      </IconButton>
    ))
    .with('button', () => (
      <CommonButton
        onClick={handleClick}
        label={label}
        data-testid="dropdown-button"
      />
    ))
    .with('arrow', () => (
      <Button
        variant="text"
        onClick={handleClick}
        className={`${disabled && styleClasses.dropdownDisable} ${
          styleClasses.dropdownButton
        }`}
        data-testid="dropdown-button"
      >
        {label}
        <ArrowDropDown />
      </Button>
    ))
    .exhaustive();

  return (
    <>
      {button}
      <Popover
        id={id}
        open={open && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={0}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <List className={styleClasses.listDropdownBg}>
          {items?.map((item) => (
            <ListItem
              onClick={() => {
                if (!item?.disabled) {
                  onItemClick?.(item);
                  handleClose();
                }
              }}
              disabled={item?.disabled}
              key={item?.title}
            >
              {item?.icon} {item?.title}
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
