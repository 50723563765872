import React, { useCallback } from 'react';
import { IUser } from 'resources/domain/entity/IUser';
import { Box, Typography } from '@mui/material';
import routes from 'constants/routes';
import { makeStyles } from '@mui/styles';
import { useDatadogAction } from 'util/datadog';
import { Theme } from '@mui/material/styles';
import { UserProfile } from 'src/services/userService/getProfile';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../services/user/useUser';
import { UserId } from 'src/services/userService/models/user.model';
import { HandleQueryStates } from './HandleQueryStates';

const useStyles = makeStyles<Theme, { isLink: boolean }>(() => ({
  cursor: {
    maxWidth: 'max-content',
    cursor: 'pointer',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const sizing = {
  tiny: {
    links: 'caption',
    middle: 'caption',
  },
  small: {
    links: 'body2',
    middle: 'caption',
  },
  medium: {
    links: 'body1',
    middle: 'body2',
  },
  large: {
    links: 'h6',
    middle: 'body1',
  },
} as const;

function StackUserWithCompany({
  user,
  size,
  children,
  onUserClick,
  onCompanyClick,
}: {
  user?: IUser | UserProfile | null;
  size: keyof typeof sizing;
  children?: React.ReactNode;
  onUserClick?(): void;
  onCompanyClick?(): void;
}) {
  const typeSize = sizing[size];
  const classes = useStyles({ isLink: user !== null });
  return (
    <Box flexWrap="wrap">
      <Typography
        className={`${onUserClick == null ? '' : classes.cursor} ${
          classes.bold
        }`}
        variant={typeSize.links}
        onClick={onUserClick}
        gutterBottom={false}
      >
        {user?.name ?? 'Anonymous'}
      </Typography>
      <Typography
        className={onCompanyClick == null ? undefined : classes.cursor}
        color="textSecondary"
        variant={typeSize.middle}
        onClick={onCompanyClick}
        gutterBottom={false}
      >
        {user?.company?.companyName ?? 'No Company'}
      </Typography>
      {children}
    </Box>
  );
}

function InnerUserWithCompany({
  user,
  size = 'medium',
  children,
  variant,
  disableLinks,
}: UserWithCompanyBaseProps & {
  // todo: eventually enforce this
  user?: IUser | UserProfile | null;
}) {
  const typeSize = sizing[size];
  const classes = useStyles({ isLink: user !== null });
  const history = useHistory();
  const companyId = user?.company?.id ?? null;

  const addError = useDatadogAction('addError');

  if (user?.company == null) {
    addError(new Error(`UserId=${user?.id} is missing or has no company`), {
      user,
    });
  }

  const handleUserClick = useCallback(() => {
    const userId = user?.id;
    if (userId) {
      history.push(`${routes.MEMBER_TABLE.replace(':id', `${userId}`)}`);
    }
  }, [history, user?.id]);

  const handleCompanyClick = useCallback(() => {
    if (companyId == null) {
      return;
    }

    history.push(`${routes.COMPANY_TABLE.replace(':id', `${companyId}`)}`);
  }, [companyId, history]);

  if (variant === 'stacked') {
    return (
      <StackUserWithCompany
        user={user}
        size={size}
        onUserClick={disableLinks ? undefined : handleUserClick}
        onCompanyClick={disableLinks ? undefined : handleCompanyClick}
      >
        {children}
      </StackUserWithCompany>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      gap="3px"
      flexWrap="wrap"
    >
      <Typography
        className={`${disableLinks ? undefined : classes.cursor} ${
          classes.bold
        }`}
        variant={typeSize.links}
        onClick={disableLinks ? undefined : handleUserClick}
        gutterBottom={false}
      >
        {user?.name ?? 'Anonymous'}
      </Typography>
      <Typography variant={typeSize.links} gutterBottom={false}>
        with
      </Typography>
      <Typography
        className={disableLinks ? undefined : classes.cursor}
        variant={typeSize.links}
        onClick={disableLinks ? undefined : handleCompanyClick}
        gutterBottom={false}
      >
        {user?.company?.companyName ?? 'No Company'}
      </Typography>
      {children}
    </Box>
  );
}

type UserWithCompanyBaseProps = {
  size?: keyof typeof sizing;
  children?: React.ReactNode;
  variant?: 'stacked' | 'inline';
  disableLinks?: boolean;
};

export default function UserWithCompany({
  user,
  ...props
}: UserWithCompanyBaseProps &
  (
    | {
        /**
         * @deprecated pass {@link userId} instead
         */
        user?: IUser | UserProfile | null;
        userId?: never;
      }
    /**
     * this is the preferred approach and will leverage trpc caching
     */
    | { userId: UserId; user?: never }
  )) {
  const queryUserId = 'userId' in props ? props.userId : undefined;
  const queriedUser = useUser(queryUserId);

  if (queryUserId != null) {
    return (
      <HandleQueryStates response={queriedUser}>
        {({ response }) => {
          const qUser = response?.data;
          return <InnerUserWithCompany {...props} user={qUser} />;
        }}
      </HandleQueryStates>
    );
  }

  return <InnerUserWithCompany {...props} user={user} />;
}
