import * as React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { uniqBy } from 'lodash';
import { CommonButton } from 'components/utilities/CommonButton';
import { HighlightOff } from '@mui/icons-material';
import { useThemeStyles } from 'components/styles';
import { useCallback } from 'react';

type Action =
  | { type: 'add_dialog'; dialogProps: ActiveDialog }
  | { type: 'close_dialog'; id: ActiveDialog['id'] };
type Dispatch = (action: Action) => void;
type DialogProviderProps = { children: React.ReactNode };
type ActiveDialog<InternalState = unknown> = {
  id: string;
  content:
    | React.ReactNode
    | ((args: {
        confirmDisabled: boolean;
        onConfirmDisabled(t: boolean): void;
        onStateChange(t: InternalState | undefined): void;
      }) => React.ReactNode);
  title: string;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  open?: never;
  disabled?: boolean;
  onConfirm?<I extends InternalState | undefined>(
    activeDialog: ActiveDialog<I>,
    state: I
  ): unknown | Promise<unknown>;
} & Partial<Omit<DialogProps, 'open'>>;

type State = { dialogs: ActiveDialog[] };

const initialState: State = {
  dialogs: [],
};

const dialogStateContext = React.createContext<ActiveDialog[] | undefined>(
  undefined
);

const dialogDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function dialogReducer(previousState: State, action: Action) {
  switch (action.type) {
    case 'add_dialog':
      return {
        ...previousState,
        dialogs: uniqBy(
          [action.dialogProps].concat(previousState.dialogs),
          'id'
        ),
      };

    case 'close_dialog':
      return {
        ...previousState,
        dialogs: previousState.dialogs.filter((d) => d.id !== action.id),
      };
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-extra-semi
      ((_unhandled: never) => null)(action);
      throw new Error(`Unhandled action=${action}`);
  }
}

function useDialogStateContext(): ActiveDialog[] {
  const context = React.useContext(dialogStateContext);
  if (context === undefined) {
    throw new Error(
      'useDialogStateContext must be used within a GlobalDialogStateProvider'
    );
  }

  return context;
}
function useDialogDispatchContext(): Dispatch {
  const context = React.useContext(dialogDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useDialogDispatchContext must be used within a GlobalDialogDispatchProvider'
    );
  }

  return context;
}

function GlobalDialogProvider({
  children,
}: DialogProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(dialogReducer, initialState);

  return (
    <dialogDispatchContext.Provider value={dispatch}>
      <dialogStateContext.Provider value={state.dialogs}>
        {children}
      </dialogStateContext.Provider>
    </dialogDispatchContext.Provider>
  );
}

export { GlobalDialogProvider, useDialogStateContext };

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: '15px',
  },
}));

const useActions = (): State & {
  onClose: (id: ActiveDialog['id']) => void;
} => {
  const dialogs = useDialogStateContext();
  const dispatch = useDialogDispatchContext();

  return {
    dialogs,
    onClose: useCallback(
      (id) => {
        dispatch({ type: 'close_dialog', id });
      },
      [dispatch]
    ),
  };
};

export function useDialog(): {
  showDialog<InternalState = unknown>(
    dialogProps: ActiveDialog<InternalState>
  ): void;
} {
  const dispatch = useDialogDispatchContext();

  return {
    showDialog: useCallback(
      (dialogProps) => {
        dispatch({ type: 'add_dialog', dialogProps });
      },
      [dispatch]
    ),
  };
}

function Content<InternalState = unknown>({
  onClose,
  ...d
}: {
  onClose(id: ActiveDialog['id']): void;
} & ActiveDialog): React.ReactElement {
  const [confirmDisabled, setConfirmDisabled] = React.useState(
    d.disabled ?? false
  );

  /**
   * sometimes we need to store values from the child, this is a means to do that and pass them into the confirmation handler
   */
  const [internalState, setInternalState] = React.useState<InternalState>();

  const handleConfirm = useCallback(
    (state: InternalState | undefined) => {
      onClose(d.id);
      d.onConfirm?.(d, state);
    },
    [onClose, d]
  );

  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {typeof d.content === 'function' ? (
        d.content({
          confirmDisabled,
          onConfirmDisabled: setConfirmDisabled,
          onStateChange: setInternalState,
        })
      ) : (
        <DialogContentText id="alert-dialog-description">
          {d.content}
        </DialogContentText>
      )}
      <DialogActions>
        <CommonButton
          disabled={confirmDisabled}
          label={d.submitButtonLabel ?? 'Yes'}
          onClick={() => handleConfirm(internalState)}
        />
        <CommonButton
          onClick={() => onClose(d.id)}
          label={d.cancelButtonLabel ?? 'Nevermind'}
          outlined
        />
      </DialogActions>
    </DialogContent>
  );
}

export function GlobalDialog(): React.ReactElement | null {
  const { dialogs, onClose } = useActions();
  const classes = useStyles();
  const theme = useTheme();
  const commonStyleClasses = useThemeStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (dialogs.length === 0) {
    // to prevent a dialog from flashing, just return nothing
    return null;
  }

  return (
    <Box borderRadius="15px">
      {dialogs.map(({ content, onConfirm, ...d }) => (
        <Dialog
          key={d.id}
          open
          onClose={() => onClose(d.id)}
          fullWidth
          fullScreen={fullScreen}
          {...d}
          classes={classes}
          aria-labelledby={`dialog-${d.title}`}
          // aria-describedby={`dialog-${d.}`}
        >
          <Box className={commonStyleClasses.tagModalCommon}>
            <DialogTitle>{d.title}</DialogTitle>
            <HighlightOff
              className={`${commonStyleClasses.cursorA} ${commonStyleClasses.mr20}`}
              onClick={() => onClose(d.id)}
            />
          </Box>
          <Content
            {...d}
            content={content}
            onConfirm={onConfirm}
            onClose={onClose}
          />
        </Dialog>
      ))}
    </Box>
  );
}
