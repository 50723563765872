// Interface for the arguments that can be passed to the utli limit function:
interface TextLimitArguments {
  text: string | undefined;
  limit?: number;
}

/** Return the string with ellipsis if the length > 100 otherwise we can return the string a it is.
    We can make the length dynamic aswell.
*/
export const getTextWithEllipsis = ({
  text,
  limit = 50,
}: TextLimitArguments): string => {
  return text
    ? text.length > limit
      ? `${text.slice(0, limit)} ...`
      : text
    : '';
};
