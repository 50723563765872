import { Checkbox } from '@mui/material';
import React, { useCallback } from 'react';
import {
  getNotificationItemProps,
  Notification,
} from './getNotificationItemProps';
import makeStyles from '@mui/styles/makeStyles';
import headerBg from '../../../assets/images/background-mixedcolors.jpg';
import useOnNotificationClick from './useOnNotificationClick';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    backgroundImage: `linear-gradient(rgba(255,255,255,.10), rgba(255,255,255,.10)), url(${headerBg})`,
  },
}));
export function useGetNotificationItem({
  onChange,
}: {
  onChange(id: Notification['id']): void;
}) {
  const classes = useStyles();
  const onNotificationClick = useOnNotificationClick();

  return useCallback(
    ({
      notification,
      isChecked,
    }: {
      notification: Notification;
      isChecked: boolean;
    }) => {
      const props = getNotificationItemProps(notification);
      return {
        id: notification.id,
        isActive: false,
        icon: (
          <Checkbox
            className={classes.checkbox}
            color="default"
            checked={isChecked}
            onChange={() => onChange(notification.id)}
          />
        ),
        onClick: () => onNotificationClick(notification),
        color:
          'ruleId' in notification.metaData
            ? ('purple' as const)
            : ('blue' as const),
        ...props,
      };
    },
    [classes.checkbox, onChange, onNotificationClick]
  );
}
