import React from 'react';
import { Avatar, AvatarProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { srcIsEqual } from './MemoImage';
import { omit } from 'lodash';

const avatarSizeMapping = {
  small: {
    width: 20,
    height: 20,
    marginRight: '-5px',
  },
  medium: {
    width: 30,
    height: 30,
    marginRight: '-7px',
  },
  large: {
    width: 40,
    height: 40,
    marginRight: '-09px',
  },
  xlarge: {
    width: 55,
    height: 55,
    marginRight: '-11px',
  },
};
export type AvatarSize = keyof typeof avatarSizeMapping;

type StyleProps = {
  color?: string | null;
  avatarSize?: AvatarSize;
  shift?: 'left';
  cursor?: 'pointer';
  remainingMembersCount?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  avatar: ({ color, avatarSize = 'large', shift, cursor }) => {
    const mapping = avatarSizeMapping[avatarSize];
    const finalColor = color !== undefined ? color : theme.palette.primary.dark;

    return {
      ...(cursor && { cursor }),
      ...(shift == null ? omit(mapping, 'marginRight') : mapping),
      ...(finalColor != null && {
        backgroundColor: finalColor,
        color: theme.palette.getContrastText(finalColor),
      }),
      '& img': {
        zIndex: 2,
      },
      fontSize: '0.8rem',
    };
  },
}));

function MemoAvatar({
  color,
  avatarSize,
  shift,
  cursor,
  remainingMembersCount,
  ...props
}: Omit<AvatarProps, keyof StyleProps> & StyleProps) {
  const classes = useStyles({ color, avatarSize, shift, cursor });

  if (remainingMembersCount) {
    return (
      <Avatar
        {...props}
        classes={{ root: classes.avatar }}
        sx={{ zIndex: 3 }}
      >{`+${remainingMembersCount}`}</Avatar>
    );
  }

  return <Avatar {...props} classes={{ root: classes.avatar }} />;
}

export default React.memo(MemoAvatar, srcIsEqual);
