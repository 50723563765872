import { useLogoutUserFromPushNotifications } from 'services/notifications/pushNotifications';
import { addDatadogError, useDataDogLogout } from '../util/datadog';
import { useRoundtableAuth0 } from 'services/auth/hooks/useRoundtableAuth0';
import { useDisconnectPushersChannels } from '../services/notifications/pusherChannels';
import { useCallback } from 'react';

export const useLogout = (): { logout: () => void } => {
  const { logout: auth0Logout } = useRoundtableAuth0();
  const stopPushNotifications = useLogoutUserFromPushNotifications();
  const { removeUserAndEndSession } = useDataDogLogout();
  const disconnectChannels = useDisconnectPushersChannels();

  const logout = useCallback(async () => {
    try {
      await Promise.all([
        disconnectChannels().catch((error) => {
          addDatadogError(error, { context: 'logout' });
        }),
        stopPushNotifications().catch((error) => {
          addDatadogError(error, { context: 'logout' });
        }),
      ]);

      removeUserAndEndSession();
      auth0Logout({ returnTo: `${window.location.origin}/login` });
    } catch (error) {
      addDatadogError(error, { context: 'logout' });
    }
  }, [
    auth0Logout,
    disconnectChannels,
    removeUserAndEndSession,
    stopPushNotifications,
  ]);
  return { logout };
};
