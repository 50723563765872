import suggestMatch from 'autosuggest-highlight/match';
import suggestParse from 'autosuggest-highlight/parse';

export function useHighlightedText(
  text: string,
  matchText: string,
  options?: Parameters<typeof suggestMatch>[2]
) {
  const matches = suggestMatch(text, matchText, {
    insideWords: true,
    ...options,
  });
  //  todo: if the text is really long, we might cut it off before highlighting the first match
  return suggestParse(text, matches);
}
