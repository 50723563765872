import { UserId } from 'src/services/userService/models/user.model';
import React, { useMemo } from 'react';
import { AccountCircle, Business, PeopleAlt } from '@mui/icons-material';
import routes from '../../../constants/routes';
import { IconWithToolTip } from '../../common/icons';
import { HeaderMenuItemLink } from './HeaderIcons';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function useHeaderTabs(userId?: UserId | null) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const iconSize = isMobile ? 'medium' : 'small';

  return useMemo(
    () =>
      [
        {
          title: 'Feed',
          icon: <IconWithToolTip fontSize={iconSize} icon="homeFeed" />,
          link: routes.HOME,
          isAdminFunctionality: false,
        },
        {
          title: 'Groups',
          icon: <PeopleAlt fontSize={iconSize} />,
          link: routes.MY_GROUPS,
          isAdminFunctionality: false,
        },
        {
          title: 'Members',
          icon: <AccountCircle fontSize={iconSize} />,
          link: routes.MEMBERS,
          isAdminFunctionality: false,
        },
        {
          title: 'Companies',
          icon: <Business fontSize={iconSize} />,
          link: routes.COMPANIES,
          isAdminFunctionality: false,
        },
        {
          title: 'My Table',
          icon: <IconWithToolTip fontSize={iconSize} icon="table" />,
          link:
            userId == null || userId === 0
              ? routes.LOGIN
              : routes.MEMBER_TABLE.replace(':id', `${userId}`),
          isAdminFunctionality: false,
        },
      ] satisfies HeaderMenuItemLink[],
    [iconSize, userId]
  );
}
