import { combineReducers } from 'redux';
import authReducer from './authReducer';

/**
 * All the reducers will connect with react app and manages the global state
 */

const reducers = combineReducers({
  /**
   * @deprecated - use trpc.users.get instead
   */
  authReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
