const roots = {
  BASE: process.env.PUBLIC_URL,
  HOME: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  LOGOUT: '/logout',
  COMPANIES: '/companies',
  MEMBERS: '/members',
  MY_GROUPS: '/groups',
  INVOICE: '/invoice',
  MESSAGING: '/messaging',
} as const;

const routes = {
  ...roots,
  /**
   * profile
   * todo: nest these
   */
  AUTH_CALLBACK: `${roots.LOGIN}/callback`,
  // AUTH_CALLBACK: roots.HOME, // todo: figure out what a callback route failed
  SIGN_UP: '/sign-up',
  PROFILE_SETTINGS: `${roots.PROFILE}/settings`,
  COMPANY: '/company',
  SETUP: '/setup',
  RULES: '/rules',
  NOTIFICATIONS: `${roots.PROFILE}/notifications`,
  AUTOMATIONS: '/automations',

  /**
   * companies
   */
  COMPANY_TABLE: `${roots.COMPANIES}/:id/table`,
  COMPANIES_MAP: `${roots.COMPANIES}/directory`,

  /**
   * members
   */
  MEMBER_TABLE: `${roots.MEMBERS}/:id/table`,
  MEMBERS_SALES: `${roots.MEMBERS}/sales`,

  /**
   * groups
   */
  GROUP_DETAIL: `${roots.MY_GROUPS}/discover/:id`,
  GROUP_FEED: `${roots.MY_GROUPS}/feed/:id`,
} as const;

export default routes;
