import React from 'react';
import Routes from './Routes';
import { SnackbarNotification } from 'components/notification/SnackbarNotification';
import { GlobalDialog } from 'components/notification/GlobalDialog';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAxiosInterceptor } from './util/http';
import { useInitializedDatadog } from './util/datadog';
import PwaInstallPrompt from 'components/prompts/PwaInstallPrompt';
import { useInitializeOneSignal } from './services/notifications/oneSignal/useInitializeOneSignal';
// import NotificationPrompt from './components/prompts/NotificationsPrompt';

const App = () => {
  useInitializeOneSignal();
  useAxiosInterceptor();
  useInitializedDatadog();

  // warning: do not useDialog here. depending on the dialog context at this
  // level will cause large rerenders on dialog open/close which in turn may
  // cause components to unmount/remount and lose state.

  return (
    <>
      <Routes />
      <SnackbarNotification />
      <GlobalDialog />
      <PwaInstallPrompt />
      {/* onesignal shows a prompt already */}
      {/*<NotificationPrompt />*/}
      <ReactQueryDevtools position="bottom-left" />
    </>
  );
};

export default App;
