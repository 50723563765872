import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import routes from './constants/routes';
import PublicRoute from './PublicRoute';
import PrivateRoutes from './PrivateRoutes';
import ErrorBoundary from 'components/utilities/ErrorBoundary';

import Loader from 'components/utilities/Loader';
import { styled } from '@mui/system';

const Main = styled('main')({
  height: '100vh',
});

const Invoice = React.lazy(() => import('./components/views/Invoice'));

const Login = React.lazy(() => import('./components/auth/LoginView'));
const PageNotFound = React.lazy(
  () => import('./components/views/PageNotFound')
);

export default function Routes() {
  return (
    <Main>
      <Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <Switch>
            <PublicRoute path={routes.INVOICE} component={Invoice} />
            <PublicRoute path={routes.LOGIN} component={Login} />
            <PrivateRoutes />
            <PublicRoute component={PageNotFound} />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </Main>
  );
}
