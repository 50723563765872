import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { AlertProps } from '@mui/lab';
import { Card, CardActions, CardContent, Grid, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { addDatadogError } from 'util/datadog';
import { CommonButton } from 'components/utilities/CommonButton';
import config from '../../config/config';

interface ErrorBoundaryProps extends AlertProps {
  children: React.ReactElement;
  ignoreError?: boolean;
  className?: string;
}

const ErrorCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(10),
  width: '45vw',
  borderRadius: 15,
}));

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error?: Error & { code?: string } }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    addDatadogError(error, {
      errorPathname: window.location.pathname,
      ...errorInfo,
    });
  }

  render() {
    const { hasError, error } = this.state;
    if (this.props.ignoreError) {
      return this.props.children;
    }
    if (hasError) {
      return (
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item md={6}>
            <ErrorCard title={`Unexpected ${error?.name}:`}>
              <CardContent>
                <Alert severity="error" variant="outlined" {...this.props}>
                  <AlertTitle>An unexpected error has occurred</AlertTitle>
                  {error?.toString()}
                  {config.appEnv === 'development' ? (
                    <Typography variant="caption">{error?.stack} </Typography>
                  ) : null}
                  {error?.code != null && (
                    <Typography variant="body1">Code: {error?.code}</Typography>
                  )}
                </Alert>
              </CardContent>
              <CardActions>
                <CommonButton
                  size="large"
                  onClick={() => window.location.reload()}
                  label="Refresh"
                />
              </CardActions>
            </ErrorCard>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}
