import { Box, List, ListItem, Typography } from '@mui/material';
import colors from 'theme/colors';
import mixedColors from 'assets/images/background-mixedcolors.jpg';
import React from 'react';
import { useThemeStyles } from '../styles';
import { makeStyles } from '@mui/styles';
import MemoAvatar from '../common/images/MemoAvatar';
import { InfiniteScroll } from 'components/common/InfiniteScroll';

type AvatarProps =
  | {
      avatar?: string;
    }
  | {
      icon?: React.ReactElement;
    };

type Item = {
  id: string | number;
  isActive: boolean;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  onClick?(item: Item): void;
  color?: 'blue' | 'purple';
} & AvatarProps;

const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blue.active,
    },
  },
  subTitle: {
    color: colors.grey.main,
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
}));

function ItemAvatar(props: AvatarProps) {
  if ('avatar' in props) {
    return <MemoAvatar src={props.avatar} alt={'list-item-avatar'} />;
  }
  if ('icon' in props) {
    return <>{props.icon}</>;
  }
  return <MemoAvatar src={mixedColors} alt={'list-item-avatar'} />;
}

type InfiniteScrollProps = {
  next(): void;
  hasMore: boolean;
  isLoading: boolean;
  endMessage: React.ReactNode;
  scrollableTarget: string;
};

type NotInfiniteScrollable = {
  hasMore?: never;
  isLoading?: boolean;
};

type ListItemProps<I extends Item> = {
  items: I[];
};

function ListInner<I extends Item>({ items }: ListItemProps<I>) {
  const classes = useStyles();
  const appClasses = useThemeStyles();

  return (
    <>
      {items.map((item) => (
        <ListItem key={item.id}>
          <Box
            bgcolor={
              item.isActive
                ? colors[item.color ?? 'blue'].active
                : colors[item.color ?? 'blue'].faded
            }
            borderRadius="100px"
            display="flex"
            alignItems="center"
            px={2}
            py={1}
            width="100%"
            sx={
              item.onClick
                ? {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: colors[item.color ?? 'blue'].active,
                    },
                  }
                : undefined
            }
          >
            <ItemAvatar {...item} />
            <Box
              pl={1}
              width="100%"
              onClick={() => {
                // item is here because other parts of this item might be clickable
                item.onClick?.(item);
              }}
            >
              {typeof item.title === 'string' ? (
                <b>
                  <Typography variant="body1" className={appClasses.lineClamp}>
                    {item.title}
                  </Typography>
                </b>
              ) : (
                item.title
              )}

              {item.subTitle && typeof item.subTitle === 'string' ? (
                <Typography
                  className={classes.subTitle}
                  variant="caption"
                  color="textSecondary"
                >
                  {item.subTitle}
                </Typography>
              ) : (
                item.subTitle
              )}
            </Box>
          </Box>
        </ListItem>
      ))}
    </>
  );
}

export default function CommonList<I extends Item>({
  items,
  isLoading,
  ...infiniteScrollProps
}: ListItemProps<I> &
  (InfiniteScrollProps | NotInfiniteScrollable)): JSX.Element {
  if ('next' in infiniteScrollProps) {
    return (
      <List>
        <InfiniteScroll
          dataLength={items.length ?? 0}
          isLoading={!!isLoading}
          {...infiniteScrollProps}
          endMessage={
            typeof infiniteScrollProps.endMessage === 'string' ? (
              <Box px={2} mx={2}>
                <Typography variant="body1">
                  {infiniteScrollProps.endMessage}
                </Typography>
              </Box>
            ) : (
              infiniteScrollProps.endMessage
            )
          }
        >
          <ListInner items={items} />
        </InfiniteScroll>
      </List>
    );
  }

  return (
    <List>
      <ListInner items={items} />
    </List>
  );
}
