import OneSignal from 'react-onesignal';
import config from 'config/config';
import { useEffect, useRef } from 'react';
import { addDatadogError, addDatadogAction } from 'util/datadog';

async function runOneSignal() {
  OneSignal.Debug.setLogLevel(config.oneSignal.debugLevel);

  await OneSignal.init({
    appId: config.oneSignal.appId,
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerParam: { scope: '/onesignal' },
    serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js',
    serviceWorkerUpdaterPath: 'onesignal/OneSignalSDKUpdateWorker.js',
    autoResubscribe: true,
    // autoRegister: true,
    notifyButton: {
      enable: true,
    },
  }).catch((error) => {
    addDatadogError('Failed To Initialize OneSignal For Push', {
      error,
      userAgent: navigator.userAgent,
    });
  });
}

export function useInitializeOneSignal() {
  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current) return;

    runOneSignal().then(() => {
      addDatadogAction('Push Notifications Initialized');
      isInitialized.current = true;
    });
  }, []);
}
