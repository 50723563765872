export function userIsOnIosDevice() {
  //  todo: consider replacing with https://developer.mozilla.org/en-US/docs/Web/API/User-Agent_Client_Hints_API

  // this check false positives on macbooks.
  // const isIos13Ipad =
  //   navigator.userAgent.includes('Mac') && 'ontouchend' in document;

  const isOnIosDevice = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform);

  return isOnIosDevice;
}

// all browsers on ios devices include "safari" in their user agent,
// but they include these identifiers if they are actually chrome/firefox
// example (chrome on iPad): Mozilla/5.0 (iPad; CPU OS 16_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/121.0.6167.171 Mobile/15E148 Safari/604.1
// see here https://stackoverflow.com/a/31732310
const nonSafariBrowsers = /(fxios|crios|opera|opt)/gi;

export function browserIsSafari() {
  if (nonSafariBrowsers.test(navigator.userAgent)) {
    return false;
  }

  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // a little counterintuitive; see:
  // https://stackoverflow.com/a/23522755/2472445
}

export function supportsPushNotifications() {
  return 'PushManager' in window;
}
