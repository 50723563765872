import React from 'react';
import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  darkBorderSearch: {
    border: `1px solid ${colors.primary.main}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50px',
    padding: '4px 10px 4px 20px',
    '& input': {
      // padding: '8px 0',
      fontSize: '14px',
    },
  },

  darkGreySearch: {
    backgroundColor: colors.grey.fog,
    borderRadius: '50px',
    padding: '4px 10px 4px 14px',
    '& input': {
      // padding: '8px 0',
      fontSize: '14px',
      color: theme.palette.common.black,
      '&::placeholder': {
        color: '#fff',
        opacity: 1,
      },
    },
    '& svg': {
      color: theme.palette.common.white,
    },
  },

  lightBlueSearch: {
    backgroundColor: colors.grey.pearlRiver,
    borderRadius: '50px',
    padding: '4px 10px 4px 14px',
    '& input': {
      // padding: '8px 0',
      fontSize: '14px',
      color: theme.palette.common.white,
    },
    '& svg': {
      color: theme.palette.common.white,
    },
  },

  lightMatteSearch: {
    backgroundColor: colors.grey.cloudy,
    borderRadius: '50px',
    '& input': {
      padding: 12,
      fontSize: '14px',
      color: theme.palette.common.black,
    },
    '& svg': {
      paddingLeft: 4,
      color: colors.grey.charcoal,
    },
  },

  matteBg: {
    backgroundColor: colors.primary.main,
    padding: '20px',
  },
}));

export type SearchProps = {
  searchClass: keyof ReturnType<typeof useStyles>;
  iconPosition?: 'startAdornment' | 'endAdornment';
  adornmentPosition?: 'start' | 'end';
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Check if component is being viewed inside the storybook
   */
  loading?: boolean;
  storybookDemo?: boolean;
  fullWidth?: boolean;
} & TextFieldProps;

/**
 * Primary UI Search component for user interaction.
 * We just added background color for lightBlueSearch to show it well, since it is transparent in nature.
 */
export function CommonSearch({
  searchClass,
  iconPosition = 'startAdornment',
  adornmentPosition = 'start',
  placeholder = 'Search...',
  loading,
  value = '',
  ...props
}: SearchProps) {
  const styleClasses = useStyles();

  return (
    <TextField
      {...props}
      variant="standard"
      className={styleClasses[searchClass]}
      InputProps={{
        ...props.InputProps,
        [iconPosition]: (
          <InputAdornment position={adornmentPosition}>
            {loading ? <CircularProgress size={20} /> : <Search />}
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      value={value}
      placeholder={placeholder}
    />
  );
}
