import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ImageListItemBar, {
  ImageListItemBarProps,
} from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.offWhite.light,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)',
  },
}));

export function ImageBar({
  onDelete,
  title,
  ...props
}: {
  onDelete?: () => void;
} & Partial<ImageListItemBarProps>) {
  const classes = useStyles();
  return (
    <ImageListItemBar
      position="top"
      {...props}
      title={title}
      classes={{
        root: classes.titleBar,
        title: classes.title,
      }}
      actionIcon={
        onDelete != null ? (
          <IconButton
            aria-label={`delete ${title}`}
            onClick={() => onDelete()}
            size="large"
          >
            <DeleteIcon className={classes.title} />
          </IconButton>
        ) : undefined
      }
    />
  );
}
