import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import FixedHeightObservingBox from './FixedHeightObservingBox';
import useScrollRestoration from 'hooks/useScrollRestoration';

export default function Layout({ children }: { children: React.ReactNode }) {
  useScrollRestoration();

  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  return (
    <>
      <FixedHeightObservingBox at="top" onHeightChange={setPaddingTop}>
        <Header />
      </FixedHeightObservingBox>
      <div
        style={{
          paddingTop,
          paddingBottom,
        }}
      >
        {children}
      </div>
      <FixedHeightObservingBox at="bottom" onHeightChange={setPaddingBottom}>
        <Footer />
      </FixedHeightObservingBox>
    </>
  );
}
