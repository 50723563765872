import React from 'react';
import { default as ReactInfiniteScroll } from 'react-infinite-scroll-component';
import Loader from 'components/utilities/Loader';
import makeStyles from '@mui/styles/makeStyles';

type InfiniteScrollProps = {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  scrollableTarget?: string;
  isLoading: boolean;
  endMessage?: React.ReactNode;
  children: React.ReactNode;
  styles?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  infiniteScroll: {
    overflow: 'hidden !important',
  },
}));

export function InfiniteScroll({
  dataLength,
  next,
  hasMore,
  isLoading,
  endMessage,
  children,
  scrollableTarget,
  styles = {},
}: InfiniteScrollProps) {
  const { infiniteScroll } = useStyles();

  return (
    <ReactInfiniteScroll
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      className={infiniteScroll}
      style={styles}
      loader={isLoading ? <Loader /> : null}
      scrollableTarget={scrollableTarget}
      endMessage={endMessage}
    >
      {children}
    </ReactInfiniteScroll>
  );
}
