import React from 'react';
import { GroupId } from 'src/services/groupService/models/group.model';
import { HandleQueryStates } from '../HandleQueryStates';
import GroupAccess from './GroupAccess';
import { useGroupMemberCount } from 'services/groups/useGroupMemberCount';

export default function GroupDetail({
  groupId,
  isPublic,
}: {
  groupId: GroupId;
  isPublic: boolean;
}) {
  const memberCount = useGroupMemberCount({ groupId });

  return (
    <HandleQueryStates response={memberCount}>
      {({ response }) => (
        <>
          <GroupAccess isPublic={isPublic} />
          {' • '}
          {response.data} Members
        </>
      )}
    </HandleQueryStates>
  );
}
