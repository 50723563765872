import React from 'react';
import SbProvider, {
  SendbirdProviderProps,
} from '@sendbird/uikit-react/SendbirdProvider';
import { theme } from 'theme';
import config from 'config/config';
import { useActiveUser } from 'services/user/useActiveUser';
import './SendbirdStyleOverrides.css';

const colorSet = {
  '--sendbird-light-primary-400': theme.palette.primary.dark,
  '--sendbird-light-primary-300': theme.palette.primary.main,
};

/**
 * sendbird props complain about children, so we wrap sit here to make TS happy
 */
function WrappedProvider(
  props: SendbirdProviderProps & { children: React.ReactNode }
) {
  return <SbProvider {...props}>{props.children}</SbProvider>;
}

const SendbirdProvider = ({ children }: { children?: React.ReactNode }) => {
  const { data: user } = useActiveUser();

  if (user?.publicId) {
    return (
      <WrappedProvider
        appId={config.sendbird.appId}
        userId={user.publicId}
        profileUrl={user.avatar ?? undefined}
        nickname={user.name}
        colorSet={colorSet}
        config={{ logLevel: 'debug' }}
      >
        {children}
      </WrappedProvider>
    );
  }
  return <>{children}</>;
};

export default SendbirdProvider;
